<template>
    <div>
       <div :id="dongId">
           <div class="top">
               <div class="nav">
                   <img v-if="isRouterAlive" src="@/assets/logo.png" alt="" width="310" height="51" />
                   <img v-else src="@/assets/logo2.png" alt="" width="310" height="51" />
                   <div class="aaa">
                       <el-menu
                           @select="handleSelect"
                           :default-active="$route.path"
                           class="el-menu-demo"
                           mode="horizontal"
                           router
                       >
                           <el-menu-item index="/">首页</el-menu-item>
                           <el-menu-item index="/zhanshi">产品中心</el-menu-item>
                           <el-menu-item index="/success">成功案例</el-menu-item>
                           <el-menu-item index="/about">关于我们</el-menu-item>
                           <el-menu-item index="/news">新闻动态</el-menu-item>
                       </el-menu>
                   </div>
               </div>
           </div>
           <keep-alive>
               <router-view></router-view>
           </keep-alive>
       </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isRouterAlive: false,
            dongId:'',
        }
    },
    watch: {
        $route(to, from) {
            window.location.reload();
        },
    },
    mounted() {
        if (this.$route.path == '/'){
            this.isRouterAlive = true
            this.dongId = 'shouye'
        }else{
            this.isRouterAlive = false
            this.dongId = 'qita'
        }
    },
    methods:{
        handleSelect(key) {
            if (key !== '/'){
                this.isRouterAlive = false
                this.dongId = 'qita'
            }else{
                this.isRouterAlive = true
                this.dongId = 'shouye'
            }
        },
    }
}
</script>

<style scoped>
#shouye{
    .top {
        height: 60rem;
        background-image: url(@/assets/beijing.png);
        background-size: cover;
        background-position: center;
    }
    .nav {
        display: inline-block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 0;
        height: 6rem;
        background-color: rgba(0, 0, 0, 0.23);
    }
    .nav img {
        margin-top: 1rem;
        margin-left: 2rem;
    }
    .aaa {
        width: 48rem;
        margin-right: 15.5rem;
    }
    /deep/.el-carousel__container {
        height: 9.3rem;
    }
    /deep/ .el-menu{
        display: flex !important;
        justify-content: space-between !important;
        background-color: rgba(165, 42, 42, 0) !important;
        border: none !important;
    }
    /deep/ .el-menu-item{
        height: 6rem;
        line-height: 6rem;
        font-size: 1.12rem;
        font-weight: 400;
        color: #fff;
    }
    /deep/.el-menu-item.is-active{
        color: #fff !important;
        border-bottom: 6px solid transparent !important;
        border-image:  linear-gradient(90deg, rgba(0, 5, 54, 1) 0%, rgba(0, 104, 183, 1) 48.13%, rgba(0, 5, 55, 1) 100%) 1 !important;
        background-color: transparent !important;
    }
    .el-menu .el-menu-item:hover{
        color: #fff !important;
        border-bottom: 6px solid transparent !important;
        border-image:  linear-gradient(90deg, rgba(0, 5, 54, 1) 0%, rgba(0, 104, 183, 1) 48.13%, rgba(0, 5, 55, 1) 100%) 1 !important;
        background-color: transparent !important;
    }
    .el-menu .el-menu-item:active{
        color: #fff !important;
        border-bottom: 6px solid transparent !important;
        border-image:  linear-gradient(90deg, rgba(0, 5, 54, 1) 0%, rgba(0, 104, 183, 1) 48.13%, rgba(0, 5, 55, 1) 100%) 1 !important;
        background-color: transparent !important;
    }
}
#qita{
    .nav {
        display: inline-block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 0;
        height: 6rem;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.6) 0%,
            rgba(255, 255, 255, 0.6) 100%
        );
    }
    .nav img {
        margin-top: 1rem;
        margin-left: 2rem;
    }
    .aaa {
        width: 48rem;
        margin-right: 15.5rem;
    }
    /deep/ .el-menu {
        display: flex;
        justify-content: space-between;
        background-color: rgba(165, 42, 42, 0);
        border: none !important;
    }
    /deep/ .el-menu-item {
        height: 6rem;
        line-height: 6rem;
        font-size: 1.12rem;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }
    /deep/.el-menu-item.is-active {
        color: rgba(51, 51, 51, 1) !important;
        border-bottom: 6px solid transparent !important;
        border-image: linear-gradient(
            90deg,
            rgba(0, 5, 54, 0) 0%,
            rgba(0, 7, 77, 1) 48.13%,
            rgba(0, 5, 55, 0) 100%
        )
        1 !important;
        background-color: transparent !important;
    }
    .el-menu .el-menu-item:hover {
        color: rgba(51, 51, 51, 1) !important;
        border-bottom: 6px solid transparent !important;
        border-image: linear-gradient(
            90deg,
            rgba(0, 5, 54, 0) 0%,
            rgba(0, 7, 77, 1) 48.13%,
            rgba(0, 5, 55, 0) 100%
        )
        1 !important;
        background-color: transparent !important;
    }
    .el-menu .el-menu-item:active {
        color: rgba(51, 51, 51, 1) !important;
        border-bottom: 6px solid transparent !important;
        border-image: linear-gradient(
            90deg,
            rgba(0, 5, 54, 0) 0%,
            rgba(0, 7, 77, 1) 48.13%,
            rgba(0, 5, 55, 0) 100%
        )
        1 !important;
        background-color: transparent !important;
    }
}
</style>
