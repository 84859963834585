<template>
    <div>
        <div class="zi1">
            <div class="zuo1">
                <span class="yinbao1">共同释放数据力量</span>
                <span class="english1">LET'S RELEASE THE POWER OF DATA TOGETHER</span>
            </div>
            <div class="you1">
                <img class="img2" src="../../assets/about.gif" alt="" />
            </div>
        </div>
        <div class="center">
            <div class="centers">
                <div class="shangs">
                    <div style="padding: 0 21rem;height: 35rem;">
                        <div class="topz">
                            <span class="cgal">公司简介</span>
                            <img src="../../assets/xian.png" alt="" class="xian" />
                            <span class="by">COMPANY PROFILE</span>
                        </div>
                        <div style=" margin-top:40px;">
                            <div class="box">
                                <div class="zuozi">
                                    <span class="gsm">山西和信基业科技股份有限公司</span>
                                    <span class="yingwen">SHANXI HEXINJIYE SCIENCE&TECHNOLOGY CO.,LTD</span>
                                </div>
                                <div>
                                    <div class="youzia">公司从事工矿生产领域自动化、信息化、智能化建设与相关智能化系统研发，累计完成百余项煤矿三化项目升级改造，形成了以数据融合为技术核心的工业生产场景下数字智能服务系统，并成功应用于煤矿智慧矿山、煤矿智能综采工作面、智能焦化厂，及以工业生产为核心的智慧物流等领域。</div>
                                    <div class="youzib">公司在服务客户的过程中，坚持“一企一策”的解决方案思路，以实现工矿生产领域的信息化、智能化为前提和基础，以“云、大、物、移、智、区”等新型核心技术为依托，以安全高效生产为核心，构建多专业、多岗位、多系统（平台）的精益化智能协作体系，对工矿生产、经营管理、职业健康与安全、技术支持与后勤保障等过程进行透彻感知、多源融合、深度分析、智能决策、高效处理，最终实现安全、无人、高效、绿色的工矿生产场景建设。</div>
                                </div>
                            </div>
                            <div class="watch" style="float: right;">
                                <videoPlayer
                                    class="vjs-custom-skin video Player"
                                    ref="videoplayer"
                                    :playsinline="true"
                                    autoplay
                                    :options="playerOptions"
                                    customEventName="changed"
                                >
                                </videoPlayer>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="zhongz">
                    <div style="padding: 0 21rem">
                        <div class="topz1">
                            <span class="cgal">发展历程</span>
                            <img src="../../assets/xian.png" alt="" class="xian" />
                            <span class="by">OUR HISTORY</span>
                        </div>
                        <div class="fazhan">
                            <img class="picdx" src="../../assets/month.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="xiax">
                    <div style="padding: 0 21rem">
                        <div class="topz">
                            <span class="cgal">企业荣誉</span>
                            <img src="../../assets/xian.png" alt="" class="xian" />
                            <span class="by">ENTERPRISE HONORS</span>
                        </div>
                        <div style="margin-top: 3.125rem;">
                            <div class="below">
                                <div style="float:left;margin-top: 6px;">
                                    <span class="genian">2013</span>
                                    <ul>
                                        <li class="diandian">山西省网络安全和信息化行业技术中心；</li>
                                    </ul>
                                    <span class="genian">2015</span>
                                    <ul>
                                        <li class="diandian">山西新三板联盟发起单位;</li>
                                    </ul>
                                    <span class="genian">2016</span>
                                    <ul>
                                        <li class="diandian">山西信息安全研究院发起单位;</li>
                                    </ul>
                                    <span class="genian">2017</span>
                                    <ul class="dianzheng">
                                        <li>山西省传感器产业联盟理事单位;</li>
                                        <li>山西省工业控制系统与安全生产联盟理事单位;</li>
                                        <li>中国科学院地理信息与文化科技产业基地（太原）;</li>
                                        <li>路网安全智能服务平台获世界物联网博览会新技术新产品成果优秀奖;</li>
                                        <li>《空间结构安全监测平台》入选世界物联网博览会工信部的优秀案例;</li>
                                        <li>公司机器视觉系列产品和基于声发射的桥梁过载及损伤监测技术全国建材测试创新大赛优秀奖;</li>
                                    </ul>
                                </div>
                                <div style="float:right">
                                    <span class="genian2">2018</span>
                                    <ul class="dianthree">
                                        <li>山西省“四新”中小企业;</li>
                                        <li>山西省物联网产业技术联盟理事单位；</li>
                                        <li>桥梁结构健康与安全国家重点实验室山西工作站；</li>
                                    </ul>
                                    <span class="genian2">2019</span>
                                    <ul class="dianthree">
                                        <li>甘肃省信息技术职教集团理事单位;</li>
                                        <li>甘肃省交通运输职教集团理事单位;</li>
                                        <li>《路网安全智能服务平台》获2019“创响山西”最具投资价值产品（项目）;</li>
                                    </ul>
                                    <span class="genian2">2020</span>
                                    <ul class="dianthree">
                                        <li>山西省轨道交通产业技术联盟优秀会员单位；</li>
                                        <li>民营科技企业证书;</li>
                                    </ul>
                                    <span class="genian2">2021</span>
                                    <ul class="dianthree">
                                        <li>山西省专精特新中小企业；</li>
                                        <li>连续四次获高新技术企业称号；</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="jianzhuang">
                                <jiangli/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ditu">
                <div class="guodu">
                    <el-breadcrumb class="dangqian" separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }"
                        >当前位置：首页</el-breadcrumb-item
                        >
                        <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                    </el-breadcrumb>
                    <a @click="goScrollTop" class="fanhui">
                        <img
                            class="fhimg"
                            src="../../assets/jiantou.png"
                            alt=""

                        />
                        <div style="line-height: 20px">Top</div>
                    </a>
                </div>
                <div class="tupic">
                    <!--                <dituTwo />-->
                    <mapPic />
                </div>
                <div class="fried">
                    <zhichi/>
                </div>
            </div>
        </div>
        <bottom />
    </div>
</template>

<script>
import bottom from "./bottom.vue";
import jiangli from './lunbo/jiangli.vue';
import zhichi from './lunbo/zhichi.vue';
import mapPic from "@/components/paibang/mapPic.vue";
import {videoPlayer} from "vue-video-player/src";
import "video.js/dist/video-js.css";
export default {
    data() {
        return {
            banner: [
                { imgUrl: require("@/assets/zhichi1.png") },
                { imgUrl: require("@/assets/zhichi2.png") },
                { imgUrl: require("@/assets/zhichi3.png") },
                { imgUrl: require("@/assets/zhichi4.png") },
            ],
            playerOptions: {
                autoplay: true,
                muted: true, // 默认情况下将会消除任何音频。
                loop: true, // 是否视频一结束就重新开始。

                language: 'zh-CN',
                aspectRatio: '16:9',
                fluid: true,
                sources: [{
                    type: "video/mp4",
                    src: require("@/assets/gandong.mp4"),
                }],
                notSupportedMessage: '此视频暂无法播放，请稍后再试',
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            },
        };
    },
    mounted() {
        // this.refresh()
    },
    methods: {
        goScrollTop() {
            scrollTo(0, 0);
        },
    },
    components: {
        bottom,
        mapPic,
        jiangli,
        zhichi,
        videoPlayer
    },
};
</script>

<style scoped>
.zi1 {
    height: 450px;
    /* margin-top: 7px; */
    background-image: linear-gradient(
        90deg,
        rgba(0, 111, 196, 1) 0%,
        rgba(0, 111, 196, 0) 100%
    );
    @media screen and (max-width: 1280px) {
        height: 280px;
        /* margin-top: 7px; */
        background-image: linear-gradient(
            90deg,
            rgba(0, 111, 196, 1) 0%,
            rgba(0, 111, 196, 0) 100%
        );
    }
}
.zuo1 {
    float: left;
    width: 28.5rem;
    height: 6.25rem;
    margin-top: 8.75rem;
    margin-left: 27.6rem;
    @media screen and (max-width: 1280px) {

    }
}
.yinbao1 {
    position: absolute;
    height: 3rem;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 9px;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 3rem;
        font-size: 35px;
        font-weight: 300;
        letter-spacing: 9px;
        line-height:2rem;
        color: rgba(255, 255, 255, 1);
    }
}
.english1 {
    position: absolute;
    height: 1.5rem;
    margin-top: 4.68rem;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 1.5rem;
        margin-top: 5.68rem;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 1);
    }
}
.img2 {
    position: relative;
    top: 23px;
    margin-left: 9.75rem;
    width: 32.75rem;
    height: 25.3rem;
    @media screen and (max-width: 1280px) {

    }
}

/* 中间 */
.gsm{
    display: block;
    text-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    font-size: 17px;
    font-family: 'fangzhenglantingzhun';
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        display: block;
        text-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
        font-size: 14px;
        font-family: 'fangzhenglantingzhun';
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 22px;
        color: rgba(0, 104, 183, 1);
    }
}
.box{
    float:left;
    width: 582px;
    height: 327px;
    @media screen and (max-width: 1280px) {
        float:left;
        width: 400px;
        height: 260px;
    }
}
.yingwen{
    text-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-family: 'fangzhenglantingxi';
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 22px;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {

    }
}
.youzia{
    width: 582px;
    height: 107.34px;
    opacity: 1;
    font-family: 'fangzhenglantingxi';
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 27px;
    color: rgba(51, 51, 51, 1);
    margin-top: 18.88px;
    margin-bottom: 18.88px;
    @media screen and (max-width: 1280px) {
        width: 400px;
        height: 80px;
        opacity: 1;
        font-family: 'fangzhenglantingxi';
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 27px;
        color: rgba(51, 51, 51, 1);
        margin-top: 18.88px;
        margin-bottom: 18.88px;
    }
}


.watch{
    width: 580px;
    height: 380px;
    @media screen and (max-width: 1280px) {
        width: 350px;
        height: 200px;
    }
}


@media screen and (max-width: 1280px) {
    /deep/.video-js{
        width: 400px;
        /*height: 200px;*/
    }
}

.below{
    float: left;
    width: 43.75rem;
}




.youzib{
    width: 582px;
    height: 134.18px;
    opacity: 1;
    font-family: 'fangzhenglantingxi';
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 27px;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        width: 400px;
        height: 100px;
        opacity: 1;
        font-family: 'fangzhenglantingxi';
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 27px;
        color: rgba(51, 51, 51, 1);
    }
}
.guodu {
    height: 4rem;
    background-color: #fff;
    padding: 0px 22rem;
    border-top: 1px solid rgba(229, 229, 229, 1);
    @media screen and (max-width: 1280px) {

    }
}
.dangqian {
    float: left;
    font-size: 14px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    line-height: 4rem;
    color: rgba(89, 89, 89, 1);
    @media screen and (max-width: 1280px) {
        float: left;
        font-size: 10px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        line-height: 4rem;
        color: rgba(89, 89, 89, 1);
    }
}
.fanhui {
    float: right;
    cursor: pointer;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {

    }
}
.shangs {
    height: 35rem;
    @media screen and (max-width: 1280px) {

    }
}
.topz {
    padding-top: 3.75rem;
    text-align: center;
    @media screen and (max-width: 1280px) {

    }
}
.topz1 {
    padding-top: 2.77rem;
    text-align: center;
    @media screen and (max-width: 1280px) {
        padding-top:10.77rem;
        text-align: center;
    }
}
.video-player.vjs-custom-skin.videoPlayer{
    width: 582px !important;
    height: 327px !important;
    @media screen and (max-width: 1280px) {
        width: 520px !important;
        height: 327px !important;
    }
}
.fazhan{
    position: relative;
    display: flex;
    box-sizing: border-box;
    @media screen and (max-width: 1280px) {
        position: relative;
        display: flex;
        box-sizing: border-box;
        left: -600px;
    }
}

/*.imgUrltu{*/
/*    width:376px;*/
/*    height:234px;*/
/*    position: relative;*/
/*    @media screen and (max-width: 1280px) {*/
/*        width:300px;*/
/*        height:200px;*/
/*        position: relative;*/
/*    }*/
/*}*/
/*.imgUrltu >>>img{*/
/*    width:80%;*/
/*    @media screen and (max-width: 1280px) {*/
/*        width:100%;*/
/*    }*/
/*}*/

/*.picdx{*/
/*    width: 1140px;*/
/*    height: 303px;*/
/*    @media screen and (max-width: 1280px) {*/
/*       .picdx>>>img{*/
/*           width: 100%;*/
/*       }*/
/*    }*/
/*}*/

.fazhan img{
    width: 1140px;
    height: 303px;
    margin: 0 auto;
    margin-top: 3.5rem;
    @media screen and (max-width: 1280px) {
        margin: 0 auto;
        margin-top: 3.5rem;
        margin-left: 60rem;
        width: 800px;
        height: 203px;
    }
}
.cgal {
    height: 1.81rem;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.75rem;
    color: rgba(1, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        font-size: 19px;
    }
}
.xian {
    display: block;
    width: 10rem;
    margin: 0.5rem auto;
    @media screen and (max-width: 1280px) {
        margin: 0.85rem auto;
    }
}
.by {
    height: 1.68rem;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.68rem;
    color: rgba(145, 145, 145, 1);
    @media screen and (max-width: 1280px) {
        font-size: 13px;
    }
}
.zhongz {
    height: 35.3rem;
    background-image: url(../../assets/zhongz.png);
    background-size: 100% 100%;
    background-position: center;
    @media screen and (max-width: 1280px) {
        height: 45.3rem;
    }
}
.xiax {
    height: 48.625rem;
    background: rgba(250, 250, 250, 1);
    @media screen and (max-width: 1280px) {

    }
}
.genian{
    font-family: 'fangzhenglantingda';
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.375rem;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        font-family: 'fangzhenglantingda';
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.375rem;
        color: rgba(51, 51, 51, 1);
    }
}
.diandian{
    width: 16.625rem;
    height: 1.75rem;
    opacity: 1;
    margin-top: 0.875rem;
    margin-bottom: 0.7rem;
    font-family: 'fangzhenglantingxi';
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        width: 16.625rem;
        height: 1.75rem;
        opacity: 1;
        margin-top: 0.875rem;
        margin-bottom: 0.7rem;
        font-family: 'fangzhenglantingxi';
        font-size: 9px;
        font-weight: 300;
        line-height: 14px;
        color: rgba(51, 51, 51, 1);
    }
}
.dianzheng{
    width: 20rem;
    font-family: 'fangzhenglantingxi';
    margin-top: 0.875rem;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5rem;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        width: 20rem;
        font-family: 'fangzhenglantingxi';
        margin-top: 0.875rem;
        font-size: 9px;
        font-weight: 300;
        line-height: 1.5rem;
        color: rgba(51, 51, 51, 1);
    }
}
.genian2{
    font-family: 'fangzhenglantingda';
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.375rem;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        font-family: 'fangzhenglantingda';
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1rem;
        color: rgba(51, 51, 51, 1);
    }
}
.dianthree{
    width: 20rem;
    margin-bottom: 0.625rem;
    font-family: 'fangzhenglantingxi';
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        width: 20rem;
        margin-bottom: 0.625rem;
        font-family: 'fangzhenglantingxi';
        font-size: 9px;
        font-weight: 300;
        line-height: 20px;
        color: rgba(51, 51, 51, 1);
    }
}
.jianzhuang{
    float: right;
    width: 29.25rem;
    height: 19.1rem;
    margin-top: 8rem;
    @media screen and (max-width: 1280px) {

    }
}
.tupic {
    width: 100%;
    height: 30rem;
    @media screen and (max-width: 1280px) {

    }
}
#containerTwo {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
    @media screen and (max-width: 1280px) {

    }
}
.fried {
    height: 9.3rem;
    line-height: 9.3rem;
    padding: 0px 21rem;
    @media screen and (max-width: 1280px) {

    }

}
.fhimg{
    width: 25px;
    height: 15px;
    margin-top: 10px;
    @media screen and (max-width: 1280px) {
        width: 20px;
        height: 10px;
        margin-top: 10px;
    }
}
</style>