import Vue from "vue";
import VueRouter from 'vue-router'

import Shouye from "../components/paibang/Shouye.vue"
import Zhanshi from '../components/paibang/Zhanshi.vue'
import Success from "../components/paibang/Success.vue";
import About from "@/components/paibang/About.vue";
import News from "@/components/paibang/News.vue";
import guank from "@/components/paibang/successAl/guank.vue"
import detail from "@/components/paibang/newsXQ/detail.vue"

Vue.use(VueRouter);

const routes =[
    {
        path: '/',
        name: '首页',
        component: Shouye,
    },
    {
        path: '/Zhanshi',
        name: '产品中心',
        component: Zhanshi,
    },
    {
        path: '/Success',
        name: '成功案例',
        component: Success,
    },
    {
        path: '/About',
        name: '关于我们',
        component: About,
    },
    {
        path: '/News',
        name: '新闻动态',
        component: News,
    },
    {
        path: '/guank',
        name: '产品中心案例',
        component: guank,
    },
    {
        path: '/detail',
        name: 'detail',
        component: detail,
    },
]

const  scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
        return savedPosition;
    }
    return { x: 0, y:0 };
}

const router = new VueRouter({
    routes,
    scrollBehavior
})
export default router
