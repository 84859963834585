<template>
    <div id="container" style="width:100%; height:30rem;"></div>
</template>

<script>

export default {
  mounted() {
      // this.mapdata();
      // console.error('每次加载--', Date.now());
      setTimeout(() => {
          this.MP('x6yZPYaqxkbXgngPsgOQYlAG4Wu0G3yI').then((BMap) => {
              const map = new BMap.Map('container');
              var point = new BMap.Point(112.561386, 37.824761); // 创建点坐标
              map.centerAndZoom(point, 15);
              var marker = new BMap.Marker(point); // 创建标注
              map.addOverlay(marker);
              map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
              var opts = { position: new BMap.Point(112.561386, 37.824761) };
              // 创建文本标注对象
              var label = new BMap.Label("和信商座", opts);
              // 自定义文本标注样式
              label.setStyle({
                  color: "black",
                  borderRadius: "5px",
                  borderColor: "#ccc",
                  padding: "10px",
                  fontSize: "16px",
                  height: "30px",
                  lineHeight: "30px",
                  fontFamily: "微软雅黑",
              });
              map.addOverlay(label);
          });
      });
  },
  methods:{
      MP(ak) {
          return new Promise(((resolve, reject) => {
              if (!window.BMap) {
                  const script = document.createElement('script');
                  script.type = 'text/javascript';
                  script.src = `http://api.map.baidu.com/api?v=2.0&ak=${ak}&callback=init`;
                  script.onerror = reject;
                  document.head.appendChild(script);
                  console.error('window.BMap', window.BMap);
                  setTimeout(() => {
                      resolve(window.BMap);
                  }, 1000); // 这里延迟1秒是关键，要不显示不出来
              }
          }));
      },
    // mapdata() {
    //       var map = new BMap.Map("container"); // 创建Map实例
    //       var point = new BMap.Point(112.561386, 37.824761); // 创建点坐标
    //       map.centerAndZoom(point, 15);
    //       var marker = new BMap.Marker(point); // 创建标注
    //       map.addOverlay(marker);
    //       map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
    //       var opts = { position: new BMap.Point(112.561386, 37.824761) };
    //       // 创建文本标注对象
    //       var label = new BMap.Label("和信商座", opts);
    //       // 自定义文本标注样式
    //       label.setStyle({
    //           color: "black",
    //           borderRadius: "5px",
    //           borderColor: "#ccc",
    //           padding: "10px",
    //           fontSize: "16px",
    //           height: "30px",
    //           lineHeight: "30px",
    //           fontFamily: "微软雅黑",
    //       });
    //       map.addOverlay(label);
    //   },
  }
}
</script>
<style scoped>

</style>