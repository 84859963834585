<template>
    <div class="swiper mySwiper1">

        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <img class="pic" src="@/assets/zhichi1.png" alt="">
            </div>
            <div class="swiper-slide">
                <img class="pic" src="@/assets/zhichi2.png" alt="">
            </div>
            <div class="swiper-slide"><img class="pic" src="@/assets/zhichi3.png" alt=""></div>
            <div class="swiper-slide"><img class="pic" src="@/assets/zhichi4.png" alt=""></div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "@/swiper/swiper-bundle.min.js";
import "@/swiper/swiper-bundle.min.css";
export default {
    mounted(){
        this.zhichibo()
    },
    methods:{
        zhichibo(){
            var swiper = new Swiper(".mySwiper1", {
                slidesPerView: 4,
                spaceBetween: 1,
                slidesPerGroup: 4,
                loop: true,
                loopFillGroupWithBlank: true,
                pagination: {
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        }
    }
}
</script>

<style scoped>
.swiper.mySwiper1.swiper-container-initialized.swiper-container-horizontal {
    height: 9.3rem;
    @media screen and (max-width: 1280px) {
        height: 9.3rem;
    }
}
.pic{
    width: 255px;
    height: 47px;
    @media screen and (max-width: 1280px) {
        width: 160px;
        height: 40px;
    }
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    @media screen and (max-width: 1280px) {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
}
.swiper-button-prev{
    left: 1px !important;
    width: 48px;
    height: 48px;
    @media screen and (max-width: 1280px) {
        left: -13px !important;
        width: 48px;
        height: 44px;
    }
}
.swiper-button-next{
    right: 1px !important;
    width: 48px !important;
    height: 48px !important;
    @media screen and (max-width: 1280px) {
        right: -13px !important;
        width: 48px !important;
        height: 44px !important;
    }
}

</style>