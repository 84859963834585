 <template>
    <div>
        <div class="zi1">
            <div class="zuo1">
                <span class="yinbao1">技术支撑行业领域</span>
                <span class="english1">TECHNICAL SUPPORT INDUSTRY FIELD</span>
            </div>
            <div class="you1">
                <img class="img2" src="@/assets/cgal.gif" alt="" />
            </div>
        </div>
        <div class="center" v-loading="loading"  element-loading-text="拼命加载中" element-loading-background="rgba(128,128,128, 0.3)">
            <div class="juzhong">
                <div class="topz">
                    <div class="fwl">访问量{{obj.pageView}}次</div>
                    <span class="cgal">案例-{{obj.caseName}}</span>
                    <img src="@/assets/xian.png" alt="" class="xian" />
                    <span class="by">BY USE CASE</span>
                </div>
                <div class="neiR">
                    <div class="picdx" v-html="obj.caseContent"></div>
                </div>
            </div>
            <div class="guodu">
                <el-breadcrumb class="dangqian" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">当前位置：首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/success' }">成功案例</el-breadcrumb-item>
                    <el-breadcrumb-item>案例-{{obj.caseName}}</el-breadcrumb-item>
                </el-breadcrumb>
                <a @click="goScrollTop" class="fanhui">
                    <img
                        class="fhimg"
                        src="@/assets/jiantou.png"
                        alt=""
                    />
                    <div style="line-height: 20px">Top</div>
                </a>
            </div>
        </div>
        <bottom/>
    </div>
</template>

<script>
import Bottom from "@/components/paibang/bottom.vue";
import axios from "axios";

export default {
    components: {Bottom},
    data() {
        return {
            myId: '',
            obj:"",
            loading:true
        }
    },
    mounted() {
        this.myId = this.$route.query.id
        this.init()
    },
    methods:{
        goScrollTop() {
            scrollTo(0, 0)
        },
        init(){
            axios
                .get(`http://47.94.237.134:8081/business/cases/${this.myId}`)
                .then((res) => {
                    console.log(res,'999');
                    this.obj = res.data.data;
                    this.loading = false
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    }
}
</script>

<style scoped>
.neiR{
    margin-top: 10px;
}
.center {
    background-image: url(@/assets/dadada.png);
    background-size: 100% 100%;
    background-position: center;
}
.juzhong {
    /*height: 110rem;*/
    padding: 0 18rem;
}
.picdx >>>img {
    width: 100%;
}
.topz {
    padding-top: 3.75rem;
    text-align: center;
}
.cgal {
    height: 1.81rem;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.75rem;
    color: rgba(1, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        font-size: 20px;
    }
}
.xian {
    display: block;
    width: 10rem;
    margin: 0.5rem auto;
    @media screen and (max-width: 1280px) {
        margin: 0.85rem auto;
    }
}
.by {
    height: 1.68rem;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.68rem;
    color: rgba(145, 145, 145, 1);
    @media screen and (max-width: 1280px) {
        font-size: 14px;
    }
}
.qianti{
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-top: 50px;
}
.guodu {
    height: 4rem;
    background-color: #fff;
    padding: 0px 22rem;
    border-top: 1px solid rgba(229, 229, 229, 1);
}
.dangqian {
    float: left;
    font-size: 14px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    line-height: 4rem;
    color: rgba(89, 89, 89, 1);
    @media screen and (max-width: 1280px) {
        float: left;
        font-size: 10px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        line-height: 4rem;
        color: rgba(89, 89, 89, 1);
    }
}
.fanhui {
    float: right;
    cursor: pointer;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 104, 183, 1);
}
.fhimg{
    width: 25px;
    height: 15px;
    margin-top: 10px;
    @media screen and (max-width: 1280px) {
        width: 20px;
        height: 10px;
        margin-top: 10px;
    }
}
.zi1 {
    height: 450px;
    /* margin-top: 7px; */
    background-image: linear-gradient(
        90deg,
        rgba(0, 111, 196, 1) 0%,
        rgba(0, 111, 196, 0) 100%
    );
    @media screen and (max-width: 1280px) {
        height: 280px;
        /* margin-top: 7px; */
        background-image: linear-gradient(
            90deg,
            rgba(0, 111, 196, 1) 0%,
            rgba(0, 111, 196, 0) 100%
        );
    }
}
.zuo1 {
    float: left;
    width: 28.5rem;
    height: 6.25rem;
    margin-top: 8.75rem;
    margin-left: 27.6rem;
}
.yinbao1 {
    position: absolute;
    height: 3rem;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 9px;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 3rem;
        font-size: 35px;
        font-weight: 300;
        letter-spacing: 9px;
        line-height:2rem;
        color: rgba(255, 255, 255, 1);
    }
}
.english1 {
    position: absolute;
    height: 1.5rem;
    margin-top: 4.68rem;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 1.5rem;
        margin-top: 5.68rem;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 1);
    }
}
.img2 {
    position: relative;
    top: 29px;
    margin-left: 3.75rem;
    width: 30.75rem;
    height: 23.3rem;
}
.fwl{
    margin-left: 900px;
    margin-top: -30px;
    opacity: 1;
    /** 文本1 */
    font-size: 17px;
    letter-spacing: 0px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    vertical-align: top;

}
</style>