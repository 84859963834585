<template>
    <div>
        <div class="zi1">
            <div class="zuo1">
                <span class="yinbao1">公司行业大事记</span>
                <span class="english1">NEWS & CHRONICIE</span>
            </div>
            <div class="you1">
                <img class="img2" src="@/assets/news.gif" alt="" />
            </div>
        </div>
        <div class="dahezi" v-loading="loading"  element-loading-text="拼命加载中" element-loading-background="rgba(128,128,128, 0.3)">
            <div class="wenzi">
                <div class="content">

                    <div class="title">{{ obj.trendsName }}</div>
                    <div class="time">
                        <div class="times">{{ (obj.createTime) }}</div>
                    </div>
                    <hr/>
                    <div class="word" v-html="obj.trendsContent">{{ obj.trendsContent }}</div>
                </div>
            </div>
        </div>
        <div class="guodu">
            <el-breadcrumb id="daohangt" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">当前位置：首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/News' }">新闻动态</el-breadcrumb-item>
                <el-breadcrumb-item v-if="this.myId == 2">公司动态</el-breadcrumb-item>
                <el-breadcrumb-item v-else-if="this.myId == 1">行业新闻</el-breadcrumb-item>
                <el-breadcrumb-item>正文</el-breadcrumb-item>
            </el-breadcrumb>
            <a @click="goScrollTop" class="fanhui">
                <img
                    class="fhimg"
                    src="@/assets/jiantou.png"
                    alt=""

                />
                <div style="line-height: 20px">Top</div>
            </a>
        </div>
        <bottom />
    </div>
</template>

<script>
import axios from 'axios'
import bottom from "@/components/paibang/bottom.vue";
export default {
    components: {bottom},
    data(){
        return{
            myId:'',
            obj:[],
            loading:true
        }
    },
    mounted() {
        this.myId = this.$route.query.id
        this.detail()
        this.getPartTime()
        this.getPartTimes()
    },
    methods:{
        goScrollTop() {
            scrollTo(0, 0);
        },
        detail(){
            axios.get(`http://47.94.237.134:8081/business/updates/${this.myId}`).then((res)=>{
                this.obj = res.data.data
                this.loading = false
                // console.log(res.data.data)
            })
        },
    }
}

</script>

<style>
.zi1 {
    height: 450px;
    /* margin-top: 7px; */
    background-image: linear-gradient(
        90deg,
        rgba(0, 111, 196, 1) 0%,
        rgba(0, 111, 196, 0) 100%
    );
    @media screen and (max-width: 1280px) {
        height: 280px;
        /* margin-top: 7px; */
        background-image: linear-gradient(
            90deg,
            rgba(0, 111, 196, 1) 0%,
            rgba(0, 111, 196, 0) 100%
        );
    }
}
.zuo1 {
    float: left;
    width: 28.5rem;
    height: 6.25rem;
    margin-top: 8.75rem;
    margin-left: 27.6rem;
}
.yinbao1 {
    position: absolute;
    height: 3rem;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 9px;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 3rem;
        font-size: 35px;
        font-weight: 300;
        letter-spacing: 9px;
        line-height:2rem;
        color: rgba(255, 255, 255, 1);
    }
}
.english1 {
    position: absolute;
    height: 1.5rem;
    margin-top: 4.68rem;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 1.5rem;
        margin-top: 5.68rem;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 1);
    }
}
.img2 {
    position: relative;
    margin-left: 3.75rem;
    width: 30.75rem;
    height: 23.3rem;
}

.dahezi{
    width: 100%;
    /*height: 915px;*/
    display: flex;
    background-image: url(@/assets/newDi.png);
    background-size: 100% 100%;
    background-position: center;
    @media screen and (max-width: 1280px) {
        width: 100%;
        height: 858px;
        display: flex;
        background-image: url(@/assets/newDi.png);
        background-size: 100% 100%;
        background-position: center;
    }

    .wenzi{
        width: 1200px;
        margin-left: 360px;
        @media screen and (max-width: 1280px) {
            width: 900px;
            margin-left: 240px;
        }
        .title{
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 24px;
            color: rgba(51, 51, 51, 1);
            text-align: justify;
            margin-top: 40px;
            @media screen and (max-width: 1280px) {
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 24px;
                color: rgba(51, 51, 51, 1);
                text-align: justify;
                margin-top: 57px;
            }
        }
        .word{
            margin-top: 18px;
            font-size: 17px;
            font-weight: 300;
            letter-spacing: 0px;
            line-height: 32px;
            color: rgba(51, 51, 51, 1);
            text-align: justify;
            vertical-align: top;
            @media screen and (max-width: 1280px) {
                width: 800px;
                margin-top: 13px;
                font-size: 11px;
                font-weight: 300;
                letter-spacing: 0px;
                line-height: 32px;
                color: rgba(51, 51, 51, 1);
                text-align: justify;
                vertical-align: top;
            }

        }
    }
}
.time{
    left: 448.56px;
    top: 644px;
    width: 143.52px;
    height: 24px;
    opacity: 1;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(189, 189, 189, 1);
    text-align: justify;
    vertical-align: top;
    margin-top: 12px;
    @media screen and (max-width: 1280px) {
        left: 448.56px;
        top: 644px;
        width: 143.52px;
        height: 24px;
        opacity: 1;
        /** 文本1 */
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(189, 189, 189, 1);
        text-align: justify;
        vertical-align: top;
        margin-top: 9px;
    }
}
hr{
    position: relative;
    left: -460px;
    width: 2000px;
    margin-top: 31px;
    height: 0px;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 1280px) {
        position: relative;
        left: -460px;
        width: 1487px;
        margin-top: 20px;
        height: 0px;
        opacity: 1;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
}
.guodu {
    height: 4rem;
    background-color: #fff;
    padding: 0px 22rem;
    border-top: 1px solid rgba(229, 229, 229, 1);
}
#daohangt {
    float: left;
    font-size: 14px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    line-height: 4rem;
    color: rgba(89, 89, 89, 1);
    @media screen and (max-width: 1280px) {
        float: left;
        font-size: 13px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        line-height: 4rem !important;
        color: rgba(89, 89, 89, 1);
    }
}
.fanhui {
    float: right;
    cursor: pointer;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 104, 183, 1);
}
.fhimg{
    width: 25px;
    height: 15px;
    margin-top: 10px;
    @media screen and (max-width: 1280px) {
        width: 20px;
        height: 10px;
        margin-top: 10px;
    }
}
</style>