<template>
    <div>
        <div class="zi1">
            <div class="zuo1">
                <span class="yinbao1">公司行业大事记</span>
                <span class="english1">NEWS & CHRONICIE</span>
            </div>
            <div class="you1">
                <img class="img2" src="../../assets/news.gif" alt="" />
            </div>
        </div>
        <div class="center">
            <div class="centershang">
                <el-tabs :stretch="true" v-model="editableTabsValue" type="card" @tab-click="handleClick">
                    <el-tab-pane label="全部" name="first">
                        <div v-for="(item,index) in newwen" :key="index">
                            <div class="dahezi">
                                <div class="image">
                                    <img :src="item.picture" alt="">
                                </div>
                                <div class="wenzi">
                                    <div class="fwl">访问量{{item.pageView+item.pageView-item.pageView}}次</div>
                                    <div class="hezi">
                                        <div class="title">{{ item.trendsName }}</div>
                                        <div class="word">{{ item.trendsDescribe }}</div>
                                        <div class="time">
                                            <div class="times">{{ getPartTime(item.releaseTime) }}</div>
                                            <div class="timex">{{ getPartTimes(item.releaseTime) }}</div>
                                        </div>
                                    </div>
                                    <img src="@/assets/lxq.png" alt="" height="19" class="anniu" @click="godetail(item)">
                                    <img src="@/assets/jianhover.png" alt="" height="19" class="anniuhover" @click="godetail(item)">
                                </div>
                            </div>
                        </div>
                        <el-pagination
                            style="margin-left: 730px;;margin-top: 20px;margin-bottom: 20px;"
                            :page-sizes="[10, 20, 30, 40]"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :total="total"
                            :current-page="pageNum"
                        />
                    </el-tab-pane>
                    <el-tab-pane label="公司动态" name="second" >
                        <div v-for="(item,index) in newwen" :key="index">
                            <div class="dahezi">
                                <div class="image">
                                    <img :src="item.picture" alt="">
                                </div>
                                <div class="wenzi">
                                    <div class="fwl">访问量{{item.pageView}}次</div>
                                    <div class="hezi">
                                        <div class="title">{{ item.trendsName }}</div>
                                        <div class="word">{{ item.trendsDescribe }}</div>
                                        <div class="time">
                                            <div class="times">{{ getPartTime(item.releaseTime) }}</div>
                                            <div class="timex">{{ getPartTimes(item.releaseTime) }}</div>
                                        </div>
                                    </div>
                                    <img src="@/assets/jianhover.png" alt="" height="19" class="anniuhover" @click="godetail(item)">
                                    <img src="@/assets/lxq.png" alt="" height="19" class="anniu" @click="godetail(item)">
                                </div>

                            </div>
                        </div>
                        <el-pagination
                            style="margin-left: 730px;;margin-top: 20px;margin-bottom: 20px;"
                            :page-sizes="[10, 20, 30, 40]"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :total="total"
                            :current-page="pageNum"
                        />
                    </el-tab-pane>
                    <el-tab-pane label="行业新闻" name="third">
                        <div v-for="(item,index) in newwen" :key="index">
                            <div class="dahezi">
                                <div class="image">
                                    <img :src="item.picture" alt="">
                                </div>
                                <div class="wenzi">
                                    <div class="fwl">访问量{{item.pageView-1}}次</div>
                                    <div class="hezi">
                                        <div class="title">{{ item.trendsName }}</div>
                                        <div class="word">{{ item.trendsDescribe }}</div>
                                        <div class="time">
                                            <div class="times">{{ getPartTime(item.releaseTime) }}</div>
                                            <div class="timex">{{ getPartTimes(item.releaseTime) }}</div>
                                        </div>
                                    </div>
                                    <img src="@/assets/jianhover.png" alt="" height="19" class="anniuhover" @click="godetail(item)">
                                    <img src="@/assets/lxq.png" alt="" height="19" class="anniu" @click="godetail(item)">
                                </div>

                            </div>
                        </div>
                        <el-pagination
                            style="margin-left: 730px;;margin-top: 20px;margin-bottom: 20px;"
                            :page-sizes="[10, 20, 30, 40]"
                            background
                            layout="total, sizes, prev, pager, next, jumper"
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :total="total"
                            :current-page="pageNum"
                        />
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="guodu">
                <el-breadcrumb class="dangqian" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">当前位置：首页</el-breadcrumb-item>
                    <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
                </el-breadcrumb>
                <a @click="goScrollTop" class="fanhui">
                    <img
                        class="fhimg"
                        src="../../assets/jiantou.png"
                        alt=""
                    />
                    <div style="line-height: 20px">Top</div>
                </a>
            </div>
        </div>
        <bottom />
    </div>
</template>

<script>
import axios from 'axios'
import bottom from "./bottom.vue";
export default {
    data() {
        return {
            newwen:[],
            pageNum:1,
            pageSize:10,
            trendsType:'',
            total:0,
            editableTabsValue:'first'
        };
    },
    mounted(){
        this.qufen()
        this.news()
        this.getPartTime()
        this.getPartTimes()
        this.godetail()

    },
    methods:{
        qufen(){
            if(this.$route.query.name == 'second'){
                this.editableTabsValue = 'second'
            }else if(this.$route.query.name == 'third'){
                this.editableTabsValue = 'third'
            }else{
                this.editableTabsValue = 'first'
            }
        },
        goScrollTop() {
            scrollTo(0, 0);
        },
        getPartTime(val){
            var timearr = val.replace(" ", ":").replace(/:/g, "-").split("-");
            var timestr = ""+ Number(timearr[1])+ "-" + timearr[2]+"";
            return timestr;
        },
        getPartTimes(val){
            var timearr = val.replace(" ", ":").replace(/\:/g, "-").split("-");
            var timestr = timearr[0]+"";
            return timestr;
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.news()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.news()
        },
        handleClick(tab){
            if (tab.index == 1){
                this.trendsType = 0
                this.pageNum = 1
            }else if(tab.index == 2){
                this.trendsType = 1
                this.pageNum = 1
            }else{
                this.trendsType = ''
                this.pageNum = 1
            }
            this.news()
            // console.log(this.trendsType,'555')
        },
        godetail(item){
            this.$router.push({
                path:'/detail',
                query:{
                    id:item.id
                }
            })
        },
        news(){
            axios
                .get("http://47.94.237.134:8081/business/updates/list?pageNum="+this.pageNum+"&pageSize="+this.pageSize+"&trendsType="+ this.trendsType )
                .then((res) => {
                    this.newwen = res.data.rows;
                    console.log(res.data)
                    this.total = res.data.total
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    },
    components: {
        bottom,
    },
};
</script>

<style scoped>
.zi1 {
    height: 450px;
    /* margin-top: 7px; */
    background-image: linear-gradient(
        90deg,
        rgba(0, 111, 196, 1) 0%,
        rgba(0, 111, 196, 0) 100%
    );
    @media screen and (max-width: 1280px) {
        height: 280px;
        /* margin-top: 7px; */
        background-image: linear-gradient(
            90deg,
            rgba(0, 111, 196, 1) 0%,
            rgba(0, 111, 196, 0) 100%
        );
    }
}
.zuo1 {
    float: left;
    width: 28.5rem;
    height: 6.25rem;
    margin-top: 8.75rem;
    margin-left: 27.6rem;
    @media screen and (max-width: 1280px) {

    }
}
.yinbao1 {
    position: absolute;
    height: 3rem;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 9px;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 3rem;
        font-size: 35px;
        font-weight: 300;
        letter-spacing: 9px;
        line-height:2rem;
        color: rgba(255, 255, 255, 1);
    }
}
.english1 {
    position: absolute;
    height: 1.5rem;
    margin-top: 4.68rem;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 1.5rem;
        margin-top: 5.68rem;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 1);
    }
}
.img2 {
    position: relative;
    margin-left: 3.75rem;
    width: 30.75rem;
    height: 23.3rem;
    @media screen and (max-width: 1280px) {

    }
}
.dahezi{
    width: 1195px;
    height: 173px;
    display: flex;
    background: rgba(250, 250, 250, 1);
    margin-top: 20px;
    @media screen and (max-width: 1280px) {
        width: 820px;
        height: 133px;
        display: flex;
        background: rgba(250, 250, 250, 1);
        margin-top: 20px;
    }
    .image{
        width: 380px !important;
        @media screen and (max-width: 1280px) {
            width: 280px !important;
            height: 133px;
        }
        img{
            width: 350px;
            height: 133px;
            margin-top: 20px;
            margin-left: 15px;
            @media screen and (max-width: 1280px) {
                width: 250px;
                height: 110px;
                margin-top: 12px;
                margin-left: 12px;
            }
        }

    }
    .hezi{
        width: 700px;
        /*height: 100%;*/
        margin-left: 20px;
        @media screen and (max-width: 1280px) {
            width: 450px !important;
            /*height: 100%;*/
            margin-left: 20px;
        }
    }
    .wenzi{
        width: 700px;
        margin-left: 0px !important;
        @media screen and (max-width: 1280px) {
            width: 550px;
        }
        .title{
            font-size: 17px;
            letter-spacing: 1px;
            /*line-height: 24px;*/
            color: rgba(51, 51, 51, 1);
            margin-top: 30px;
            @media screen and (max-width: 1280px) {
                font-size: 12px;
                letter-spacing: 1px;
                /*line-height: 24px;*/
                color: rgba(51, 51, 51, 1);
                margin-top: 20px;
            }
        }
        .word{
            font-size: 16px;
            line-height: 24px;
            color: rgba(122, 122, 122, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @media screen and (max-width: 1280px) {
                width: 420px;
                font-size: 9px;
                line-height: 17px;
                color: rgba(122, 122, 122, 1);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .time{
            opacity: 1;
            font-size: 14px;
            line-height: 24px;
            color: rgba(122, 122, 122, 1);
            position: relative;
            left: 730px;
            top: -90px;
            @media screen and (max-width: 1280px) {
                opacity: 1;
                font-size: 11px;
                line-height: 24px;
                color: rgba(122, 122, 122, 1);
                position: relative;
                left: 470px;
                top: -85px;
            }

            .times{
                width: 64px;
                height: 24px;
                opacity: 1;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 24px;
                color: rgba(51, 51, 51, 1);
                text-align: justify;
                vertical-align: top;
                @media screen and (max-width: 1280px) {
                    width: 60px;
                    height: 20px;
                    opacity: 1;
                    font-size: 17px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 24px;
                    color: rgba(51, 51, 51, 1);
                    text-align: justify;
                    vertical-align: top;
                }

            }
            .timex{
                width: 32px;
                height: 24px;
                opacity: 1;
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 1px;
                line-height: 24px;
                color: rgba(122, 122, 122, 1);
                text-align: left;
                vertical-align: top;
                @media screen and (max-width: 1280px) {
                    width: 28px;
                    height: 20px;
                    opacity: 1;
                    font-size: 10px;
                    font-weight: 300;
                    letter-spacing: 1px;
                    line-height: 24px;
                    color: rgba(122, 122, 122, 1);
                    text-align: left;
                    vertical-align: top;
                }

            }
        }
    }
}

.center {
    padding-top: 3.625rem;
}
.centershang {
    padding: 0 22rem;

}
/deep/.el-tabs__header {
    width: 25rem;
    border: none !important;
}
/deep/.el-tabs__nav {
    border: none !important;
}
/deep/.el-tabs__item {
    margin-right: 1.5rem;
    width: 3rem !important;
    height: 1.7rem !important;
    line-height: 1.7rem;
    border: none !important;
}
/deep/.is-active {
    color: #fff !important;
    background-color: rgba(0, 104, 183, 1);
}
/deep/.el-tabs__header{
    @media screen and (max-width: 1280px) {
        width: 350px;

    }
}

/deep/.el-tabs__content{
    @media screen and (max-width: 1280px) {
        overflow: visible;
        position: relative;
    }
}

/deep/.el-tabs__item{
    @media screen and (max-width: 1280px) {
        font-size: 10px;

    }
}
/deep/.el-pagination{
    @media screen and (max-width: 1280px) {
        width: 300px;
        margin-left: 370px !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.guodu {
    height: 4rem;
    background-color: #fff;
    padding: 0px 22rem;
    border-top: 1px solid rgba(229, 229, 229, 1);
}
.dangqian {
    float: left;
    font-size: 14px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    line-height: 4rem;
    color: rgba(89, 89, 89, 1);
    @media screen and (max-width: 1280px) {
        float: left;
        font-size: 10px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        line-height: 4rem;
        color: rgba(89, 89, 89, 1);
    }
}
.fanhui {
    float: right;
    cursor: pointer;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 104, 183, 1);
}
.anniu{
    position: relative;
    left: 760px;
    top: -45px;
    cursor: pointer;
    display: block;
    @media screen and (max-width: 1280px) {
        width: 15px;
        height: 15px;
        position: relative;
        left: 500px;
        top: -45px;
    }
}
.anniuhover{
    position: relative;
    left: 760px;
    top: -45px;
    cursor: pointer;
    display: none;
    @media screen and (max-width: 1280px) {
        width: 15px;
        height: 15px;
        position: relative;
        left: 500px;
        top: -45px;
        cursor: pointer;
        /*display: block;*/
    }
}
.dahezi:hover .anniu{
    display: none !important;
}
.dahezi:hover .anniuhover{
    display: block !important;
}
.fhimg{
    width: 25px;
    height: 15px;
    margin-top: 10px;
    @media screen and (max-width: 1280px) {
        width: 20px;
        height: 10px;
        margin-top: 10px;
    }
}
.dahezi:hover .image{
    background-color: rgba(0, 104, 183, 1) !important;
}
.dahezi:hover{
    box-shadow: 3px 3px 7px  rgba(0, 0, 0, 0.2);
}
.dahezi:hover .anniu{
    background-color: #ccc;
}
.fwl{
    position: absolute;
    opacity: 1;
    font-size: 12px;
    letter-spacing: 1px;

    color: rgba(122, 122, 122, 1);
    text-align: left;
    margin-left: 714px;
    margin-top: 147px;
}
</style>