<template>
    <div>
        <div class="zi1">
            <div class="zuo1">
                <span class="yinbao1">产品引爆数据价值</span>
                <span class="english1">PRODUCT LIGHTING YOUR DATA</span>
            </div>
            <div class="you1">
                <img class="img1" src="../../assets/datu.gif" alt="" />
            </div>
        </div>
        <div class="center">
            <div class="zhong" v-loading="loading"
                 element-loading-text="拼命加载中" element-loading-background="rgba(128,128,128, 0.3)">
                <el-tabs v-model="editableTabsValue" :tab-position="tabPosition" @tab-click="handleClick" >
                    <el-tab-pane
                            id="xun"
                            v-for="(item, index) in cp"
                            :name = "item.id.toString()"
                            :key="index"
                            :label="item.productName">
                        <div class="lll">访问量{{lll}}次</div>

                    <div class="fupic" v-html="xianData" v-loading="loading"></div
                    ></el-tab-pane>

                </el-tabs>

            </div>
            <div class="guodu">
                <el-breadcrumb class="dangqian" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                    >当前位置：首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>产品中心</el-breadcrumb-item>
                </el-breadcrumb>
                <a @click="goScrollTop" class="fanhui">
                    <img
                            class="fhimg"
                            src="../../assets/jiantou.png"
                            alt=""
                    />
                    <div style="line-height: 20px">Top</div>
                </a>
            </div>
        </div>
        <bottom />
    </div>
</template>

<script>
import axios from "axios";
import bottom from "./bottom.vue";
export default {
    data() {
        return {
            lll:"",
            cp: "",
            tabPosition: "left",
            editableTabsValue:"6",
            xianData:'',
            loading : true
        };
    },
    mounted() {
        this.cpzx();
        var name = this.$route.query.id;
        this.editableTabsValue = name.toString()
    },
    methods: {
        goScrollTop() {
            scrollTo(0, 0);
        },
        handleClick(tab, event) {
           this.xianData = ''
            this.loading = true
            this.xianqing(tab.name)
        },
        cpzx() {
            axios
                .get(
                    "http://47.94.237.134:8081/business/product/getReceptionDetailsList"
                )
                .then((res) => {
                    this.cp = res.data;
                    console.log(res.data,'789')
                    this.xianqing(res.data[0].id)
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        xianqing(ids){
            axios
                .get(
                    "http://47.94.237.134:8081/business/product/"+ids
                )
                .then((res) => {
                    this.xianData = res.data.data.productContent;
                    this.lll = res.data.data.pageView
                    console.log(res.data,'456')
                    this.loading = false
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    components:{
        bottom
    }
};
</script>

<style scoped>
.zi1 {
    height: 450px;
    background-image: linear-gradient(
            90deg,
            rgba(0, 111, 196, 1) 0%,
            rgba(0, 111, 196, 0) 100%
    );
    @media screen and (max-width: 1280px) {
        height: 280px;
        background-image: linear-gradient(
                90deg,
                rgba(0, 111, 196, 1) 0%,
                rgba(0, 111, 196, 0) 100%
        );
    }
}
.zuo1 {
    float: left;
    width: 32.5rem;
    height: 6.25rem;
    margin-top: 8.75rem;
    margin-left: 27.6rem;
}
.yinbao1 {
    position: absolute;
    height: 3rem;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 9px;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 3rem;
        font-size: 35px;
        font-weight: 300;
        letter-spacing: 9px;
        line-height:2rem;
        color: rgba(255, 255, 255, 1);

    }
}
.english1 {
    position: absolute;
    height: 1.5rem;
    margin-top: 4.68rem;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 1.5rem;
        margin-top: 5.68rem;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 1);
    }
}
.img1 {
    position: relative;
    top: -55px;
    /*margin-left: 1.75rem;*/
    width: 30.1rem;
    height: 32.1rem;
    @media screen and (max-width: 1280px) {
        position: relative;
        top: -30px;
        margin-left: 3.75rem;
        width: 30.1rem;
        height: 30.1rem;
    }
}
.center {
    width: 100%;
    background-image: url(../../assets/dadada.png);
    background-size: 100% 100%;
    background-position: center;
}
.zhong {
    /*height: 105rem;*/
    padding: 0 16.6rem;
    @media screen and (max-width: 1280px) {
        padding: 0 16.6rem;
    }
}
.fupic >>>img{
    width:80%;
    @media screen and (max-width: 1280px) {
        width:100%;
    }
}
/deep/.el-tabs__header {
    float: left;
    margin-top: 3rem;
    width: 15rem;
    height: 101rem !important;
    font-size: 17px;
    font-weight: 300;
    line-height: 17px;
    color: rgb(29, 29, 32);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    @media screen and (max-width: 1280px) {

    }
}
/deep/.el-tabs__nav-scroll {
    width: 15rem;
    margin-top: 6.25rem;
    margin-right: 1.25rem;
    @media screen and (max-width: 1280px) {

    }
}
/deep/.el-tabs__active-bar {
    height: 2.5rem !important;
    @media screen and (max-width: 1280px) {
    }
}
/deep/.el-tabs__item {
    height: 4.375rem !important;
    font-family: "fangzhenglantingxi";
    font-size: 17px;
    font-weight: 300;
    color: rgba(0, 0, 0, 1);
    @media screen and (max-width: 1280px) {
        margin-left: -6px;
        font-size: 12px;

    }
}
/deep/.el-tabs__content {
    /*width: 75.25rem;*/
    /*height: 102.3rem;*/
    padding-left: 3.5rem;
    margin-top: 3rem;
    @media screen and (max-width: 1280px) {
        /*width: 80.25rem;*/
        /*height: 102.3rem;*/
        padding-left: 1.56rem;
        margin-top: 2.68rem;
    }
}
/deep/.fupic p {
    font-size: 16px;
    font-weight: 500;
}
#xun:hover {
    cursor: pointer;

}
#xun:active {
    cursor: pointer;
}
#child1 img {
    width: 72.25rem;
    height: 31.68rem;
    @media screen and (max-width: 1280px) {

    }
}
.guodu {
    height: 4rem;
    background-color: #fff;
    padding: 0px 22rem;
    border-top: 1px solid rgba(229, 229, 229, 1);
    @media screen and (max-width: 1280px) {

    }
}
.dangqian {
    float: left;
    font-size: 14px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    line-height: 4rem;
    color: rgba(89, 89, 89, 1);
    @media screen and (max-width: 1280px) {
        float: left;
        font-size: 10px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        line-height: 4rem;
        color: rgba(89, 89, 89, 1);
    }
}
.fanhui {
    float: right;
    cursor: pointer;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 104, 183, 1);
}
.fhimg{
    width: 25px;
    height: 15px;
    margin-top: 10px;
    @media screen and (max-width: 1280px) {
        width: 20px;
        height: 10px;
        margin-top: 10px;
    }
}
.lll{
    margin-left: 800px;
    opacity: 1;
    font-size: 12px;
    letter-spacing: 1px;
    color: rgba(122, 122, 122, 1);
    text-align: left;
}
/deep/.circular{
    margin-top: 50px;
}
</style>