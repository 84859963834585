<template>
  <div>
      <div class="zi">
        <div class="shangzi">
          <span style="color: #fff">仁和守信</span>&nbsp;&nbsp;&nbsp;
          <span style="color: rgba(0, 104, 183, 1)">基业长青</span>
        </div>
        <div class="xiazi">一切以智慧数据服务为企业准则</div>
      </div>
      <div class="center1">
        <div class="centers">
          <span class="title">产品中心</span>
          <span class="ying">PRODUCT DISPALY</span>
          <div class="fourtu">
            <div id="fourtu11" v-for="(item, index) in posts" :key="index" @click="chanpAnli(item)">
              <img :src="item.picture" alt="" />
              <div id="fourzi">{{ item.productName }}</div>
              <div id="fourmiao">{{ item.productDescribe }}</div>
            </div>
          </div>
        </div>
        <div class="centerx">
          <div class="neir">
            <div>
              <span class="title" style="padding-top: 50px">成功案例</span>
              <span class="ying">SUCCESSFUL CASES</span>
              <div class="fourziTu">
                <div  v-for="(item, index) in successanli" :key="index">
                    <div v-if="index == 0" class="meian">
                        <div class="successLizi">
                            <img
                                    class="biao"
                                    src="../../assets/jiaobiao0.png"
                                    alt=""
                            />
                            <span class="meizi">{{item.caseName}}</span>
                            <span class="miaoshu">{{item.caseDescribe}}</span>
                            <div class="gengd" @click="liulanAnli(item)">
                                <span>浏览更多 ></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="index == 1" class="tianjin">
                        <div class="successLizi">
                            <img
                                    class="biao"
                                    src="../../assets/jiaobiao1.png"
                                    alt=""
                            />
                            <span class="meizi">{{ item.caseName }}</span>
                            <span class="miaoshu">{{item.caseDescribe}}</span>
                            <div class="gengd" @click="liulanAnli(item)">
                                <span>浏览更多 ></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="index == 2" class="xinzhou" >
                        <div class="successLizi">
                            <img
                                    class="biao"
                                    src="../../assets/jiaobiao2.png"
                                    alt=""
                            />
                            <span class="meizi">{{ item.caseName }}</span>
                            <span class="miaoshu">{{item.caseDescribe}}</span>
                            <div class="gengd" @click="liulanAnli(item)">
                                <span>浏览更多 ></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="index == 3" class="linfen" style="display: inline-block">
                        <div class="successLizi">
                            <img
                                    class="biao"
                                    src="../../assets/jiaobiao3.png"
                                    alt=""
                            />
                            <span class="meizi">{{ item.caseName }}</span>
                            <span class="miaoshu">{{item.caseDescribe}}</span>
                            <div class="gengd" @click="liulanAnli(item)">
                                <span>浏览更多 ></span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div>
              <span class="title" style="padding-top: 70px">关于我们</span>
              <span class="ying">ABOUT US</span>
              <div class="gs">
                <span class="mingc">山西和信基业科技股份有限公司</span>
                <div class="jianjie">
                  企业致力于工矿生产领域自动化、信息化、智能化建设与相关智能化系统研发，累计完成百余项煤矿三化项目升级改造，形成了以数据融合为技术核心的工业生产场景下数字智能服务系统，并成功应用于煤矿智慧矿山、煤矿智能综采工作面、智能焦化厂，及以工业生产为核心的智慧物流等领域。公司在服务客户的过程中，坚持“一企一策”的解决方案思路，以实现工矿生产领域的信息化、智能化为前提和基础，以“云、大、物、移、智、区”等新型核心技术为依托，以安全高效生产为核心，构建多专业、多岗位、多系统（平台）的精益化智能协作体系，对工矿生产、经营管理、职业健康与安全、技术支持与后勤保障等过程进行透彻感知、多源融合、深度分析、智能决策、高效处理，最终实现安全、无人、高效、绿色的工矿生产场景建设。
                </div>
                <div class="zhuang">
                  <div class="zhuangone">
                    <div style="display: block; margin-bottom: 7px">
                      <span class="nianfen">18</span><span class="danw">年</span>
                    </div>
                    <span class="chengli">公司成立</span>
                  </div>
                  <div class="zhuangone">
                    <div style="display: block; margin-bottom: 7px">
                      <span class="nianfen">100</span><span class="danw">+</span>
                    </div>
                    <span class="chengli">专利软著</span>
                  </div>
                  <div class="zhuangone">
                    <div style="display: block; margin-bottom: 7px">
                      <span class="nianfen">18</span><span class="danw">年</span>
                    </div>
                    <span class="chengli">行业经验</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="centersan">
          <span class="title">新闻动态</span>
          <span class="ying">NEWS INFORMATION</span>
          <div>
            <div class="left">
                <img
                  style="display: block"
                  :src="zuixin.picture"
                  alt=""
                  class="leftPic"
                />
                <div class="wenben">
                  <div class="zuobian">
                    <span class="five">{{ getPartTimes(zuixin.releaseTime) }}</span>
                    <span class="riqi">{{ getPartTime(zuixin.releaseTime) }}</span>
                  </div>
                  <div class="youbian">
                    <div class="xinbiao">{{ zuixin.trendsName }}</div>
                    <div class="connei">{{ zuixin.trendsDescribe }}</div>
                  </div>
                </div>
            </div>
            <div class="rightyou">
                <el-tabs :stretch="true" type="card" @tab-click="handleClick">
                    <el-tab-pane label="公司动态" >
                        <div class="youyige">
                              <div class="zuoer">
                                <div class="zhihuik">{{xinwen.trendsName}}</div>
                                <div class="zhikuang">{{ xinwen.trendsDescribe }}</div>
                              </div>
                               <div class="youer">
                                <span
                                  class="five"
                                  style="color: rgba(89, 89, 89, 1)"
                                  >{{ getPartTimes(xinwen.releaseTime) }}</span>
                                <span
                                  class="riqi"
                                  style="color: rgba(89, 89, 89, 1);margin-right: 20px;"
                                  >{{ getPartTime(xinwen.releaseTime) }}</span>
                              </div>
                        </div>
                        <div class="chongfu" v-for="(item,index) in allNews" :key="index">
                            <div class="xun" @click="openmore(item)">
                                <span class="nengju">{{ item.trendsName }}</span>
                                <span class="riqitwo">{{item.releaseTime }}</span>
                            </div>
                        </div>
                        <div class="more" @click="allMore('second')">More ></div>
                    </el-tab-pane>
                    <el-tab-pane label="行业新闻">
                        <div class="youyige">
                            <div class="zuoer">
                                <div class="zhihuik">{{xinwen.trendsName}}</div>
                                <div class="zhikuang">{{ xinwen.trendsDescribe }}</div>
                            </div>
                            <div class="youer">
                                <span
                                        class="five"
                                        style="color: rgba(89, 89, 89, 1)"
                                >{{ getPartTimes(xinwen.releaseTime) }}</span>
                                <span
                                        class="riqi"
                                        style="color: rgba(89, 89, 89, 1);margin-right: 20px; "
                                >{{ getPartTime(xinwen.releaseTime) }}</span>
                            </div>
                        </div>
                        <div class="chongfu" v-for="(item,index) in allNews" :key="index">
                            <div class="xun" @click="openmore(item)">
                                <span class="nengju">{{ item.trendsName }}</span>
                                <span class="riqitwo">{{item.releaseTime }}</span>
                            </div>
                        </div>
                          <div class="more" @click="allMore('third')">More ></div>
                    </el-tab-pane>
                </el-tabs>
            </div>
          </div>
        </div>
        <div class="ditu">
          <div class="guodu">
            <el-breadcrumb class="dangqian" separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">当前位置：首页</el-breadcrumb-item>
            </el-breadcrumb>
            <a @click="goScrollTop" class="fanhui">
              <img
                src="../../assets/jiantou.png"
                alt=""
                class="anhui"
              />
              <div class="topShang">Top</div>
            </a>
          </div>
          <div class="tupic">
              <mapPic />
          </div>
          <div class="fried">
            <zhichi/>
          </div>
        </div>
      </div>
      <bottom />
  </div>
</template>

<script>
import axios from "axios";
import bottom from "./bottom.vue";
import zhichi from "./lunbo/zhichi.vue";
import mapPic from "./mapPic.vue";
export default {
  data() {
    return {
      posts: [],
      successanli:'',
      xinwen:'',
      zuixin:"",
      allNews:[],
      trendsType: 0
    };
  },
  mounted() {
    this.sy();
    this.anli()
    this.news()
  },
  methods: {
    goScrollTop() {
      scrollTo(0, 0);
    },
    sy() {
      axios
        .get("http://47.94.237.134:8081/business/product/getReceptionList")
        .then((res) => {
          this.posts = res.data;
          console.log(this.posts,'7777');
        })
        .catch((error) => {
          console.error(error);
        });
    },
    anli(){
        axios
            .get("http://47.94.237.134:8081/business/cases/list")
            .then((res) => {
                this.successanli = res.data.rows.splice(0,4);
                // console.log(this.successanli,'89999')
            })
            .catch((error) => {
                console.error(error);
            });
    },
    news(){
          axios
              .get("http://47.94.237.134:8081/business/updates/list?pageNum=1&pageSize=7&trendsType="+this.trendsType )
              .then((res) => {
                  console.log( res.data.rows)
                  this.allNews = res.data.rows
                  this.xinwen = res.data.rows[1]
                  this.zuixin = res.data.rows[0]
              })
              .catch((error) => {
                  console.error(error);
              });
    },
    handleClick(tab){
          if (tab.index == 0){
              this.trendsType = 0
          }else if(tab.index == 1){
              this.trendsType = 1
          }else{
              this.trendsType = 0
          }
          this.news()
      },
    getPartTime(val){
        var timearr = val.replace(" ", ":").replace(/:/g, "-").split("-");
        var timestr = ""+ Number(timearr[1])+ "-" + timearr[2]+"";
        return timestr;
    },
    getPartTimes(val){
        var timearr = val.replace(" ", ":").replace(/\:/g, "-").split("-");
        var timestr = timearr[0]+"";
        return timestr;
    },
    chanpAnli(item){
        // console.log(item,'9999')
        this.$router.push({
            path:'/zhanshi',
            query:{
                id:item.id
            }
        })
        var syName = document.getElementById('shouye')
        syName.id = 'qita'
    },
    liulanAnli(item){
      this.$router.push({
          path:'/guank',
          query:{
              id:item.id
          }
      })
      var syName = document.getElementById('shouye')
      syName.id = 'qita'
    },
    //点击跳转每一个新闻案例
    openmore(item){
        this.$router.push({
            path:'/detail',
            query:{
                id:item.id
            }
        })
        var syName = document.getElementById('shouye')
        syName.id = 'qita'
    },
    //点击更多跳转新闻所有
    allMore(type){
        this.$router.push({
            path:'/news',
            query:{
                name:type
            }
        })
        var syName = document.getElementById('shouye')
        syName.id = 'qita'
    }
  },
  components:{
    bottom,
    zhichi,
    mapPic,
  }
};
</script>

<style scoped>
.zi {
    display: inline-block;
    position: absolute;
    top: 38%;
    left: 32%;
    font-family: "fangzhenglantingzhun";

    @media screen and (max-width: 1280px) {
        display: inline-block;
        position: absolute;
        top: 38%;
        left: 33%;
        font-family: "fangzhenglantingzhun";

    }
}

.shangzi {
    width: 42rem;
    height: 5.31rem;
    opacity: 1;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 72px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 5.25rem;
    @media screen and (max-width: 1280px) {
        width: 70rem;
        height: 5.31rem;
        opacity: 1;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        font-size: 50px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 5.25rem;
    }
}

.xiazi {
    margin-left: 2.5rem;
    margin-top: 1.8rem;
    text-align: center;
    width: 36.3rem;
    height: 3.06rem;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 6px;
    line-height: 3.06rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        margin-left: 3rem;
        margin-top: 1.8rem;
        text-align: center;
        width: 36.3rem;
        height: 3.06rem;
        font-size: 21px;
        font-weight: 400;
        letter-spacing: 6px;
        line-height: 3.06rem;
        color: rgba(255, 255, 255, 1);
    }
}

.center1 {
    margin: 0 auto;
    @media screen and (max-width: 1280px) {
        margin: 0 auto;
    }
}

.centers {
    padding: 0px 22rem;
    height: 28.5rem;
    padding-top: 3rem;
    @media screen and (max-width: 1280px) {
        padding: 0px 22rem;
        height: 28.5rem;
        padding-top: 3rem;
    }
}

.title {
    display: block;
    width: 6rem;
    height: 1.5rem;
    font-family: "fangzhenglantingzhun";
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        display: block;
        width: 12rem;
        height: 3rem;
        font-family: "fangzhenglantingzhun";
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        font-size: 18px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }
}

.ying {
    width: 7.1rem;
    height: 0.8rem;
    font-family: "fangzhenglantingxi";
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 300;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        width: 7.1rem;
        height: 0.8rem;
        font-family: "fangzhenglantingxi";
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        font-size: 13px;
        font-weight: 300;
        color: rgba(51, 51, 51, 1);
    }
}

.fourtu {
    margin-top: 4.7rem;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1280px) {
        margin-top: 4.7rem;
        display: flex;
        justify-content: space-between;
    }
}

#fourtu11 {
    height: 19.5rem;
    @media screen and (max-width: 1280px) {
        height: 20.5rem;
    }
}

#fourtu11:hover {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 1280px) {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2)
    }
}

#fourtu11 img {
    width: 23.5rem;
    height: 15rem;
    @media screen and (max-width: 1280px) {
        width: 23.5rem;
        height: 15rem;
    }
}

#fourzi {
    display: flex;
    justify-content: space-around;
    margin-top: 0.5rem;
    font-size: 17px;
    @media screen and (max-width: 1280px) {
        display: flex;
        justify-content: space-around;
        margin-top: 1rem;
        font-size: 13px;
    }
}

#fourmiao {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    margin-top: 0.5rem;
    color: rgba(115, 115, 115, 1);
    @media screen and (max-width: 1280px) {
        display: flex;
        justify-content: space-around;
        font-size: 10px;
        margin-top: 0.5rem;
        color: rgba(115, 115, 115, 1)
    }
}

.zhihui {
    display: block;
    margin-top: 1.25rem;
    text-align: center;
    font-family: "fangzhenglantingzhun";
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        display: block;
        margin-top: 1.25rem;
        text-align: center;
        font-family: "fangzhenglantingzhun";
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }
}

.miao {
    display: block;
    margin-top: 0.6rem;
    text-align: center;
    font-family: "fangzhenglantingxi";
    font-size: 14px;
    font-weight: 300;
    color: rgba(115, 115, 115, 1);
    @media screen and (max-width: 1280px) {
        display: block;
        margin-top: 0.6rem;
        text-align: center;
        font-family: "fangzhenglantingxi";
        font-size: 14px;
        font-weight: 300;
        color: rgba(115, 115, 115, 1);
    }
}

.centerx {
    height: 63rem;
    background-image: url(../../assets/oneda.png);
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 1280px) {
        height: 83rem;
        background-image: url(../../assets/oneda.png);
        background-size: cover;
        background-position: center;
    }
}

.neir {
    padding: 0px 22rem;
    @media screen and (max-width: 1280px) {
        padding: 0px 22rem;
    }
}

.fourziTu {
    display: flex;
    justify-content: center;
    margin-top: 3.1rem;
    @media screen and (max-width: 1280px) {
        display: flex;
        justify-content: center;
        margin-top: 3.1rem;
    }
}

.biao {
    display: block;
    width: 40px;
    height: 40px;
    padding-top: 2rem;
    padding-left: 1.8rem;
    @media screen and (max-width: 1280px) {
        display: block;
        width: 35px;
        height: 35px;
        padding-top: 2rem;
        padding-left: 2rem;
    }
}

.meizi {
    display: block;
    margin-top: 1rem;
    margin-left: 2.1rem;
    font-size: 17px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        display: block;
        margin-top: 1.5rem;
        margin-left: 1.5rem;
        font-size: 14px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        color: rgba(51, 51, 51, 1);
    }
}
.leftPic{
    width: 488px;
    height: 290px;
    @media screen and (max-width: 1280px) {
        width: 360px;
        height: 200px;
    }
}
.rightyou{
    float: right;
    width: 38rem;
}

.miaoshu {
    display: block;
    width: 12rem;
    margin-top: 1rem;
    margin-left: 2.1rem;
    font-family: "fangzhenglantingxi";
    font-size: 15px;
    font-weight: 300;
    color: rgba(122, 122, 122, 1);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    @media screen and (max-width: 1280px) {
        display: block;
        width: 12rem;
        margin-top: 1rem;
        margin-left: 1.7rem;
        font-family: "fangzhenglantingxi";
        font-size: 12px;
        font-weight: 300;
        color: rgba(122, 122, 122, 1);
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        text-overflow: ellipsis;
    }
}
/deep/.el-tabs__header{
    width: 15rem;
    margin-left: 330px;
    border: none !important;
    @media screen and (max-width: 1280px) {
        width:25rem;
        margin-left: 160px;
    }
}
/deep/.el-tabs__nav{
    border: none !important;
    @media screen and (max-width: 1280px) {
        width: 25rem !important;
    }
}
/deep/.el-tabs__content{
    margin-top: 35px;
}
/deep/.el-tabs__item{
    width: 1.5rem;
    height: 1.7rem;
    line-height: 1.7rem;
    border: none !important;
    @media screen and (max-width: 1280px) {
        width:10rem ;
        font-size: 12px;
    }
}
/deep/.is-active{
    color: #fff !important;
    background-color:  rgba(0, 104, 183, 1);
}

.gengd {
    display: none;
    margin-top: 5.5rem;
    width: 16.25rem;
    height: 1.8rem;
    line-height: 1.8rem;
    background-color: rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        display: none;
        margin-top: 3.5rem;
        width: 16.25rem;
        height: 1.5rem;
        line-height: 1.5rem;
        background-color: rgba(0, 104, 183, 1);
    }
}

.gengd span {
    float: right;
    margin-right: 1.25rem;
    margin-top: 0.2rem;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    @media screen and (max-width: 1280px) {
        float: right;
        margin-right: 1.25rem;
        margin-top: 0.3rem;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
    }
}

.tianjin :hover {
    background-image: url(../../assets/tianji.png);
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 1280px) {
        background-image: url(../../assets/tianji.png);
        background-size: cover;
        background-position: center;
    }
}
.tianjin :hover .biao {
    background: none;
    border: none;
}

.tianjin :hover .meizi {
    color: #fff;
    background: none;
    border: none;
    @media screen and (max-width: 1280px) {
        color: #fff;
        background: none;
        border: none;
    }
}

.tianjin :hover .miaoshu {
    color: #fff;
    background: none;
    border: none;
    @media screen and (max-width: 1280px) {
        color: #fff;
        background: none;
        border: none;
    }
}

.tianjin :hover .gengd {
    display: block;
    cursor: pointer;
    background-image: none;
    @media screen and (max-width: 1280px) {
        display: block;
        cursor: pointer;
        background-image: none;
    }
}

.tianjin :hover .gengd span {
    display: block;
    background-image: none;
    @media screen and (max-width: 1280px) {
        display: block;
        background-image: none;
    }
}

.xinzhou :hover {
    background-image: url(../../assets/xinzhou.png);
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 1280px) {
        background-image: url(../../assets/xinzhou.png);
        background-size: cover;
        background-position: center;
    }
}
.xinzhou :hover .biao {
    background: none;
    border: none;
}

.xinzhou :hover .meizi {
    color: #fff;
    background: none;
    border: none;
    @media screen and (max-width: 1280px) {
        color: #fff;
        background: none;
        border: none;
    }
}

.xinzhou :hover .miaoshu {
    color: #fff;
    background: none;
    border: none;
    @media screen and (max-width: 1280px) {
        color: #fff;
        background: none;
        border: none;
    }
}

.xinzhou :hover .gengd {
    display: block;
    cursor: pointer;
    background-image: none;
    @media screen and (max-width: 1280px) {
        display: block;
        cursor: pointer;
        background-image: none;
    }
}

.xinzhou :hover .gengd span {
    display: block;
    background-image: none;
    @media screen and (max-width: 1280px) {
        display: block;
        background-image: none;
    }
}
.meian{
    display: inline-block;
    margin-right: 49px;
    @media screen and (max-width: 1280px) {
        display: inline-block;
        margin-right: 40px;
    }
}
.tianjin{
    display: inline-block;
    margin-right: 49px;
    @media screen and (max-width: 1280px) {
        display: inline-block;
        margin-right: 40px;
    }
}
.xinzhou{
    display: inline-block;
    margin-right: 49px;
    @media screen and (max-width: 1280px) {
        display: inline-block;
        margin-right: 40px;
    }
}

.meian :hover{
    background-image: url(../../assets/meian.png);
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 1280px) {
        background-image: url(../../assets/meian.png);
        background-size: cover;
        background-position: center;
    }
}
.meian :hover .biao {
    background: none;
    border: none;
}

.meian :hover .meizi {
    color: #fff;
    background: none;
    border: none;
    @media screen and (max-width: 1280px) {
        color: #fff;
        background: none;
        border: none;
    }
}

.meian :hover .miaoshu {
    color: #fff;
    background: none;
    border: none;
    @media screen and (max-width: 1280px) {
        color: #fff;
        background: none;
        border: none;
    }
}

.meian :hover .gengd {
    display: block;
    cursor: pointer;
    background-image: none;
    @media screen and (max-width: 1280px) {
        display: block;
        cursor: pointer;
        background-image: none;
    }
}
.meian :hover .gengd span {
    display: block;
    background-image: none;
}
.linfen :hover {
    background-image: url(../../assets/linfen.png);
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 1280px) {
        background-image: url(../../assets/linfen.png);
        background-size: cover;
        background-position: center;
    }
}
.linfen :hover .biao {
    background: none;
    border: none;
}
.linfen :hover .meizi {
    color: #fff;
    background: none;
    border: none;
    @media screen and (max-width: 1280px) {
        color: #fff;
        background: none;
        border: none;
    }
}
.linfen :hover .miaoshu {
    color: #fff;
    background: none;
    border: none;
    @media screen and (max-width: 1280px) {
        color: #fff;
        background: none;
        border: none;
    }
}
.linfen :hover .gengd {
    display: block;
    cursor: pointer;
    background-image: none;
    @media screen and (max-width: 1280px) {
        display: block;
        cursor: pointer;
        background-image: none;
    }
}
.linfen :hover .gengd span {
    display: block;
    background-image: none;
    @media screen and (max-width: 1280px) {
        display: block;
        background-image: none;
    }
}
.successLizi{
    display: block;
    width: 16.25rem;
    height: 19rem;
    border-bottom: 10px solid rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        display: block;
        width: 16.25rem;
        height: 19.5rem;
        border-bottom: 8px solid rgba(0, 104, 183, 1);
    }
}
.gs {
    width: 75rem;
    height: 10.5rem;
    margin-top: 70px;
    text-align: center;
    @media screen and (max-width: 1280px) {
        position: absolute;
        width: 75rem;
        height: 10.5rem;
        margin-top: 70px;
        text-align: center;
    }
}
.mingc {
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-family: "fangzhenglantingzhun";
    font-size: 18px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        font-family: "fangzhenglantingzhun";
        font-size: 18px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }
}
.jianjie {
    margin-top: 1.8rem;
    line-height: 27px;
    font-family: "fangzhenglantingxi";
    font-size: 17px;
    font-weight: 300;
    text-align: left;
    color: rgba(122, 122, 122, 1);
    @media screen and (max-width: 1280px) {
        margin-top: 1.8rem;
        line-height: 27px;
        font-family: "fangzhenglantingxi";
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        color: rgba(122, 122, 122, 1);
    }
}
.zhuang {
    display: flex;
    justify-content: space-around;
    margin-top: 2.5rem;
    width: 75rem;
    @media screen and (max-width: 1280px) {
        display: flex;
        justify-content: space-around;
        margin-top: 2.5rem;
        width: 75rem;
    }
}
.zhuangone {
    width: 4.5rem;
    height: 3.8rem;
    @media screen and (max-width: 1280px) {
        width: 7rem;
        height: 4.8rem;
    }
}
.nianfen {
    font-size: 24px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    color: rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        font-size: 24px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        color: rgba(0, 104, 183, 1);
    }
}
.danw {
    margin-left: 0.18rem;
    font-size: 12px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        margin-left: 0.18rem;
        font-size: 12px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        color: rgba(51, 51, 51, 1);
    }
}
.chengli {
    margin-top: 0.8rem;
    font-size: 16px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        margin-top: 0.8rem;
        font-size: 15px;
        font-family: "fangzhenglantingzhun";
        color: rgba(51, 51, 51, 1);
    }
}
.centersan {
    padding: 3rem 22rem;
    height: 43rem;
    @media screen and (max-width: 1280px) {
        height: 50rem;
    }
}
.left {
    float: left;
    margin-top: 5rem;
    @media screen and (max-width: 1280px) {
        float: left;
        margin-top: 5rem;
    }
}
.wenben {
    display: block;
    height: 18rem;
    background-color: rgba(0, 104, 183, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.zuobian {
    float: left;
    width: 6.25rem;
    @media screen and (max-width: 1280px) {
        float: left;
        width: 6.25rem;
    }
}
.five {
    display: block;
    width: 3.5rem;
    height: 2rem;
    margin-top: 2rem;
    margin-left: 0.8rem;
    font-size: 20px;
    font-weight: 400;
    font-family: "fangzhenglantingda";
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        display: block;
        width: 1.625rem;
        height: 1.4375rem;
        margin-top: 2rem;
        margin-left: 1.8rem;
        font-size: 18px;
        font-weight: 400;
        font-family: "fangzhenglantingda";
        color: rgba(255, 255, 255, 1);
    }
}
.riqi {
    /*width: 3rem;*/
    margin-left: 1.3rem;
    font-family: "fangzhenglantingxi";
    color: rgba(255, 255, 255, 1);
    font-size: 17px;
    font-weight: 300;
    @media screen and (max-width: 1280px) {
        display: block;
        width: 5rem;
        margin-left: 1.7rem;
        margin-top: 0.7rem;
        font-family: "fangzhenglantingxi";
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-weight: 300;
    }
}
.youbian {
    float: right;
    width: 20rem;
    @media screen and (max-width: 1280px) {
        float: right;
        width: 20rem;
        margin-right: 1rem;
    }
}
.xinbiao {
    /*width: 3.7rem;*/
    height: 1rem;
    margin-top: 2.1rem;
    margin-bottom: 1rem;
    font-family: "fangzhenglantingzhun";
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        /*width: 5.7rem;*/
        height: 1rem;
        margin-top: 2.1rem;
        margin-bottom: 1rem;
        font-family: "fangzhenglantingzhun";
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }
}
.connei {
    width: 18.4rem;
    padding-top: 0.625rem;
    font-family: "fangzhenglantingxi";
    font-size: 14px;
    font-weight: 400;
    line-height: 1.56rem;
    color: rgba(255, 255, 255, 1);
    border-top: 1px solid rgba(255, 255, 255, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 1280px) {
        width: 18.4rem;
        padding-top: 0.625rem;
        font-family: "fangzhenglantingxi";
        font-size: 11px;
        font-weight: 400;
        line-height: 1.56rem;
        color: rgba(255, 255, 255, 1);
        border-top: 1px solid rgba(255, 255, 255, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
}
.right {
    float: right;
    margin-top: 1.25rem;
    @media screen and (max-width: 1280px) {
        float: right;
        margin-top: 1.25rem;
    }
}
.youyige {
    width: 35rem;
    height: 11.8rem;
    @media screen and (max-width: 1280px) {
        width: 35rem;
        height: 10.8rem;
    }
}
.zuoer {
    float: left;
    width: 25.6rem;
    @media screen and (max-width: 1280px) {
        float: left;
        width: 25.6rem;
    }
}
.zhihuik {
    /*width: 5.4rem;*/
    height: 2.3rem;
    margin-top: 0.3rem;
    font-family: "fangzhenglantingxi";
    font-size: 18px;
    line-height: 2.3rem;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        /*width: 7rem;*/
        height: 2rem;
        margin-top: 0.6rem;
        font-family: "fangzhenglantingxi";
        font-size: 15px;
        font-weight: 300;
        line-height: 1rem;
        color: rgba(51, 51, 51, 1);
    }
}
.zhikuang {
    width: 25.5rem;
    height: 7.5rem;
    padding-top: 0.625rem;
    font-family: "fangzhenglantingxi";
    font-size: 15px;
    font-weight: 300;
    line-height: 1.3rem;
    border-top: 1px solid rgba(189, 189, 189, 1);
    color: rgba(122, 122, 122, 1);
    @media screen and (max-width: 1280px) {
        width: 25.5rem;
        height: 7.5rem;
        padding-top: 0.625rem;
        font-family: "fangzhenglantingxi";
        font-size: 10px;
        font-weight: 300;
        line-height: 1.3rem;
        border-top: 1px solid rgba(189, 189, 189, 1);
        color: rgba(122, 122, 122, 1);
    }
}
.youer {
    float: right;
    @media screen and (max-width: 1280px) {
        float: right;
    }
}
.xun {
    cursor: pointer;
    width: 35rem;
    height: 2.75rem;
    line-height: 2.75rem;
    margin-top: 1rem;
    padding: 0 0.7rem;
    border-bottom: 1px solid rgba(189, 189, 189, 1);
    @media screen and (max-width: 1280px) {
        width: 35rem;
        height: 2.75rem;
        margin-top: 1.65rem;
        border-bottom: 1px solid rgba(189, 189, 189, 1);
    }
}
.chongfu :hover{
    background-color: rgba(0, 104, 183, 1) ;
}
.chongfu :hover .nengju{
    color: #fff ;
}
.chongfu :hover .riqitwo{
    color: #fff ;
}
.nengju {
    float: left;
    font-size: 14px;
    font-weight: 300;
    font-family: "fangzhenglantingxi";
    line-height: 2.5rem;
    color: rgba(51, 51, 51, 1);
    @media screen and (max-width: 1280px) {
        float: left;
        font-size: 10px;
        font-weight: 300;
        font-family: "fangzhenglantingxi";
        line-height: 2.5rem;
        color: rgba(51, 51, 51, 1);
    }
}
.riqitwo {
    float: right;
    font-size: 15px;
    font-family: "fangzhenglantingxi";
    font-weight: 300;
    line-height: 2.5rem;
    color: rgba(89, 89, 89, 1);
    @media screen and (max-width: 1280px) {
        float: right;
        font-size: 12px;
        font-family: "fangzhenglantingxi";
        font-weight: 300;
        line-height: 2.5rem;
        color: rgba(89, 89, 89, 1);
    }
}
.more {
    float: right;
    margin-top: 1.5rem;
    margin-right: 0.18rem;
    text-align: center;
    line-height: 1.8rem;
    cursor: pointer;
    width: 5.3rem;
    height: 1.8rem;
    border: 1px solid rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        float: right;
        margin-top: 1.5rem;
        margin-right: 0.18rem;
        text-align: center;
        line-height: 1.8rem;
        cursor: pointer;
        width: 5.3rem;
        height: 1.8rem;
        border: 1px solid rgba(0, 104, 183, 1);
    }
}
.guodu {
    height: 4rem;
    margin-top: 5rem;
    padding: 0px 22rem;
    border-top: 1px solid rgba(229, 229, 229, 1);
    @media screen and (max-width: 1280px) {
        height: 4rem;
        margin-top: 5rem;
        padding: 0px 22rem;
        border-top: 1px solid rgba(229, 229, 229, 1);
    }
}
.dangqian {
    float: left;
    font-size: 14px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    line-height: 4rem;
    color: rgba(89, 89, 89, 1);
    @media screen and (max-width: 1280px) {
        float: left;
        font-size: 12px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        line-height: 4rem;
        color: rgba(89, 89, 89, 1);
    }
}
.fanhui {
    float: right;
    cursor: pointer;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        float: right;
        cursor: pointer;
        text-decoration: none;
        width: 4rem;
        height: 4rem;
        color: #fff;
        text-align: center;
        background: rgba(0, 104, 183, 1);
        /* margin-top: -4rem; */
    }
}
.anhui{
    width: 25px;
    height: 15px;
    margin-top: 15px;
    @media screen and (max-width: 1280px) {
        width:20px;
        height:10px;
        margin-top: 10px;
    }
}
.topShang{
    line-height: 20px;
    @media screen and (max-width: 1280px) {
        line-height: 15px;
    }
}
.tupic {
    width: 100%;
    height: 30rem;
    @media screen and (max-width: 1280px) {
        width: 100%;
        height: 30rem;
    }
}
#container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
    @media screen and (max-width: 1280px) {
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin: 0;
        font-family: "微软雅黑";
    }
}
.fried {
    height: 9.3rem;
    line-height: 9.3rem;
    padding: 0px 21rem;
    @media screen and (max-width: 1280px) {
        height: 9.3rem;
        line-height: 9.3rem;
        padding: 0px 21rem;
    }
}
</style>