<template>
    <div>
        <div class="zi1">
            <div class="zuo1">
                <span class="yinbao1">技术支撑行业领域</span>
                <span class="english1">TECHNICAL SUPPORT INDUSTRY FIELD</span>
            </div>
            <div class="you1">
                <img class="img2" src="../../assets/cgal.gif" alt="" />
            </div>
        </div>
        <div class="center">
            <div class="juzhong">
                <div class="topz">
                    <span class="cgal">成功案例</span>
                    <img src="../../assets/xian.png" alt="" class="xian" />
                    <span class="by">BY USE CASE</span>
                </div>
                <div class="nei">
                    <el-tabs
                            :stretch="true"
                            type="card"
                            @tab-click="handleClick"
                    >
                        <el-tab-pane label="全部" >
                            <div class="zongshow" v-for="(item,index) in anli" :key="index" style="margin-bottom: 25px;">
                                <!-- {{ item }} -->
                                <img :src="item.picture" alt="" class="imgUrltu">
                                <div class="xiang">
                                    <div class="xiang1" @click="shouAnli(item)">
                                        <div class="caseName">{{ item.caseName }}</div>
                                        <div class="miaoshu">{{ item.caseDescribe }}</div>
                                        <img src="@/assets/lxq.png" alt="" class="jiantoutwo">
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="智慧能源">
                            <div class="zongshow" v-for="(item,index) in anli" :key="index" style="margin-bottom: 25px;">
                                <!-- {{ item }} -->
                                <img :src="item.picture" alt="" class="imgUrltu">
                                <div class="xiang">
                                    <div class="xiang1" @click="shouAnli(item)">
                                        <div class="caseName">{{ item.caseName }}</div>
                                        <div class="miaoshu">{{ item.caseDescribe }}</div>
                                        <img src="@/assets/lxq.png" alt="" class="jiantoutwo">
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="智慧城市">
                            <div class="zongshow" v-for="(item,index) in anli" :key="index" style="margin-bottom: 25px;">
                                <!-- {{ item }} -->
                                <img :src="item.picture" alt="" class="imgUrltu">
                                <div class="xiang">
                                    <div class="xiang1" @click="shouAnli(item)">
                                        <div class="caseName">{{ item.caseName }}</div>
                                        <div class="miaoshu">{{ item.caseDescribe }}</div>
                                        <img src="@/assets/lxq.png" alt="" class="jiantoutwo">
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="智慧矿山">
                            <div class="zongshow" v-for="(item,index) in anli" :key="index" style="margin-bottom: 25px;">
                                <!-- {{ item }} -->
                                <img :src="item.picture" alt="" class="imgUrltu">
                                <div class="xiang">
                                    <div class="xiang1" @click="shouAnli(item)">
                                        <div class="caseName">{{ item.caseName }}</div>
                                        <div class="miaoshu">{{ item.caseDescribe }}</div>
                                        <img src="@/assets/lxq.png" alt="" class="jiantoutwo">
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <el-pagination
                        class="fenye"
                        background
                        :page-size="pageSize"
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        @size-change="handleSizeChange"
                        :current-page="pageNum"
                        :total="total"
                    />
                </div>
            </div>
            <div class="guodu">
                <el-breadcrumb class="dangqian" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">当前位置：首页</el-breadcrumb-item>
                    <el-breadcrumb-item>成功案例</el-breadcrumb-item>
                </el-breadcrumb>
                <a @click="goScrollTop" class="fanhui">
                    <img
                        class="fhimg"
                        src="../../assets/jiantou.png"
                        alt=""
                    />
                    <div style="line-height: 20px">Top</div>
                </a>
            </div>
        </div>
        <bottom />
    </div>
</template>

<script>
import axios from "axios";
import bottom from './bottom.vue';
export default {
    data() {
        return {
            anli:[],
            pageNum:1,
            pageSize:6,
            caseType:'',
            total:0,
        };
    },
    mounted(){
        this.lizi()
    },
    methods: {
        goScrollTop() {
            scrollTo(0, 0);
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.lizi()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.lizi()
        },
        handleClick(tab){
            if (tab.index == 1){
                this.caseType = 0
                this.pageNum = 1
            }else if(tab.index == 2){
                this.caseType = 1
                this.pageNum = 1
            }else if(tab.index == 3){
                this.caseType = 2
                this.pageNum = 1
            }else{
                this.caseType = ''
                this.pageNum = 1
            }
            this.lizi()
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        lizi(){
            axios
                .get("http://47.94.237.134:8081/business/cases/list?pageNum="+this.pageNum+"&pageSize="+this.pageSize+"&caseType="+ this.caseType)
                .then((res) => {
                    this.anli = res.data.rows;
                    this.total = res.data.total
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        shouAnli(item){
            this.$router.push({
                path:'/guank',
                query:{
                    id:item.id
                }
            })
        },
    },
    components:{
        bottom,
    }
};
</script>

<style scoped>
.zi1 {
    height: 450px;
    /* margin-top: 7px; */
    background-image: linear-gradient(
            90deg,
            rgba(0, 111, 196, 1) 0%,
            rgba(0, 111, 196, 0) 100%
    );
    @media screen and (max-width: 1280px) {
        height: 280px;
        /* margin-top: 7px; */
        background-image: linear-gradient(
                90deg,
                rgba(0, 111, 196, 1) 0%,
                rgba(0, 111, 196, 0) 100%
        );
    }
}
.zuo1 {
    float: left;
    width: 28.5rem;
    height: 6.25rem;
    margin-top: 8.75rem;
    margin-left: 27.6rem;
    @media screen and (max-width: 1280px) {

    }
}
.yinbao1 {
    position: absolute;
    height: 3rem;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 9px;
    line-height: 3rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 3rem;
        font-size: 35px;
        font-weight: 300;
        letter-spacing: 9px;
        line-height:2rem;
        color: rgba(255, 255, 255, 1);
    }
}
.english1 {
    position: absolute;
    height: 1.5rem;
    margin-top: 4.68rem;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        position: absolute;
        height: 1.5rem;
        margin-top: 5.68rem;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 1.5rem;
        color: rgba(255, 255, 255, 1);
    }
}
.img2 {
    position: relative;
    top: 29px;
    margin-left: 3.75rem;
    width: 30.75rem;
    height: 23.3rem;
    @media screen and (max-width: 1280px) {

    }
}
.center {
    background-image: url(../../assets/dadada.png);
    background-size: 100% 100%;
    background-position: center;
    @media screen and (max-width: 1280px) {

    }
}
.juzhong {
    height: 60rem;
    padding: 0 15rem;
    @media screen and (max-width: 1280px) {
        height: 70rem;
        padding: 0 10rem;
    }
}
.topz {
    padding-top: 3.75rem;
    text-align: center;
    @media screen and (max-width: 1280px) {

    }
}
.imgUrltu{
    width:28rem;
    height:16rem;
    position: relative;
    border-radius: 8px;
    @media screen and (max-width: 1280px) {
        width:32rem;
        height:18rem;
        position: relative;
        border-radius: 8px;
    }
}
.xiang{
    display: none;
    position: absolute;
    width:28rem;
    height:16rem;
    margin-top: -260px;
    border-radius: 8px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 1280px) {
        width:32rem;
        height:18rem;
        margin-top: -193px;
    }
}
.xiang1{
    cursor: pointer;
    margin-top: 60px;
    width: 28rem;
    height: 9rem;
    background: rgba(1, 104, 183, 0.8);
    @media screen and (max-width: 1280px) {
        cursor: pointer;
        margin-top: 35px;
        width: 32rem;
        height: 11rem;
        background: rgba(1, 104, 183, 0.8);
    }
}
.caseName{
    /*width:200px;*/
    height:20px;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    line-height: 13px;
    padding-top: 20px;
    margin: 0 auto;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        font-size: 15px;
        line-height: 13px;
        padding-top: 16px;
    }
}
.miaoshu{
    margin-top: 12px;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    padding: 0 20px;
    font-family: 'fangzhenglantingxi';
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        margin-top: 5px;
        font-size: 13px;
    }
}
.jiantoutwo{
    width: 30px;
    height: 28px;
    margin-top: 15px;
    @media screen and (max-width: 1280px) {
        width: 26px;
        height: 23px;
        margin-top: 9px;
    }
}
.zongshow{
    overflow: hidden;
}
.zongshow:hover>.xiang{
    display: block;
}
.cgal {
    height: 1.81rem;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.75rem;
    color: rgba(1, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        font-size: 20px;
    }
}
/deep/.el-tab-pane{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 3.75rem;
}
.xian {
    display: block;
    width: 10rem;
    margin: 0.5rem auto;
    @media screen and (max-width: 1280px) {
        margin: 0.85rem auto;
    }
}
.by {
    height: 1.68rem;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.68rem;
    color: rgba(145, 145, 145, 1);
    @media screen and (max-width: 1280px) {
        font-size: 14px;
    }
}
/deep/.el-tabs__header{
    width: 35rem;
    border: none !important;
    margin: 0 auto;
    @media screen and (max-width: 1280px) {
        width:45rem;
    }
}
/deep/.el-tabs__nav{
    border: none !important;
    @media screen and (max-width: 1280px) {
        width: 25rem !important;
    }
}
/deep/.el-tabs__item{
    margin-right: 1.5rem;
    width:3rem;
    height: 1.7rem;
    line-height: 1.7rem;
    border: none !important;
    @media screen and (max-width: 1280px) {
        width:10rem ;
        font-size: 12px;
    }
}
/deep/.is-active{
    color: #fff !important;
    background-color:  rgba(0, 104, 183, 1);
}
/deep/.btn-prev{
    line-height: 2.25rem;
    width:2.25rem;
    height: 2.25rem;
    background-color:transparent !important;
}
/deep/.btn-next{
    line-height: 2.25rem;
    width:2.25rem;
    height: 2.25rem;
    background-color:transparent !important;
}
/deep/.number{
    line-height: 2.25rem;
    width:2.25rem;
    height: 2.25rem;
    @media screen and (max-width: 1280px) {

    }
}
/deep/.active{
    background-color: #fff !important;
    color: #000 !important;
}
.nei {
    height: 53rem;
    margin-top: 1.875rem;
    @media screen and (max-width: 1280px) {
        height: 43rem;
        margin-top: 1.875rem;
    }
}
/deep/.el-pagination__rightwrapper{
    @media screen and (max-width: 1280px) {
        margin-right: 60px;
        margin-top: 50px;
    }
}
.guodu {
    height: 4rem;
    background-color: #fff;
    padding: 0px 22rem;
    border-top: 1px solid rgba(229, 229, 229, 1);
}
.dangqian {
    float: left;
    font-size: 14px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    line-height: 4rem;
    color: rgba(89, 89, 89, 1);
    @media screen and (max-width: 1280px) {
        float: left;
        font-size: 10px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        line-height: 4rem;
        color: rgba(89, 89, 89, 1);
    }
}
.fanhui {
    float: right;
    cursor: pointer;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {

    }
}
.fhimg{
    width: 25px;
    height: 15px;
    margin-top: 10px;
    @media screen and (max-width: 1280px) {
        width: 20px;
        height: 10px;
        margin-top: 10px;
    }
}
.fenye{
    margin-left: 73rem;
    margin-top: 2rem;
    @media screen and (max-width: 1280px) {
        margin-top: 3rem;
        margin-left: 80rem;
    }
}
</style>