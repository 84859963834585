<template>
    <div class="swiper mySwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <img src="@/assets/jiang1.png" alt="" width:="222.77" height="306" style="float:left">
                <div style="float:right">
                    <img src="@/assets/jiang2.png" alt="" width="241.23" height="151">
                    <img src="@/assets/jiang3.png" alt="" width="241.23" height="151">
                </div>
            </div>
            <div class="swiper-slide">
                <div style="float:left"> 
                    <img src="@/assets/jiang4.png" alt="" width="232" height="151" style="margin-bottom:4px">
                    <img src="@/assets/jiang6.png" alt="" width="232" height="151">
                </div>
                <div style="float:right">
                    <img src="@/assets/jiang5.png" alt="" width="232" height="151" style="margin-bottom:4px;margin-left:4px">
                    <img src="@/assets/jiang7.png" alt="" width="232" height="151" style="margin-left:4px">
                </div>
            </div>
            <div class="swiper-slide">
                <div style="float:left"> 
                    <img src="@/assets/three1.png" alt="" width="232" height="151" style="margin-bottom:4px">
                    <img src="@/assets/three2.png" alt="" width="232" height="151">
                </div>
                <div style="float:right">
                    <img src="@/assets/three3.png" alt="" width="232" height="151" style="margin-bottom:4px;margin-left:4px">
                    <img src="@/assets/three4.png" alt="" width="232" height="151" style="margin-left:4px">
                </div>
            </div>
            <div class="swiper-slide">
                <div style="float:left"> 
                    <img src="@/assets/four1.png" alt="" width="232" height="151" style="margin-bottom:4px">
                    <img src="@/assets/four2.png" alt="" width="232" height="151">
                </div>
                <div style="float:right">
                    <img src="@/assets/four3.png" alt="" width="232" height="151" style="margin-bottom:4px;margin-left:4px">
                    <img src="@/assets/four4.png" alt="" width="232" height="151" style="margin-left:4px">
                </div>
            </div>
            <div class="swiper-slide">
                <div style="float:left"> 
                    <img src="@/assets/five1.png" alt="" width="232" height="151" style="margin-bottom:4px">
                    <img src="@/assets/five2.png" alt="" width="232" height="151">
                </div>
                <div style="float:right">
                    <img src="@/assets/five3.png" alt="" width="232" height="151" style="margin-bottom:4px;margin-left:4px">
                    <img src="@/assets/five4.png" alt="" width="232" height="151" style="margin-left:4px">
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "@/swiper/swiper-bundle.min.js";
import "@/swiper/swiper-bundle.min.css";
export default {
    mounted() {
        this.lunbo();
    },
    methods:{
        lunbo(){
            var swiper = new Swiper(".mySwiper", {
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: ".swiper-pagination",
                },
            });
        },
    }
}
</script>

<style scoped>
.mySwiper {
  width: 29.25rem;
  height: 19.1rem;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullets{
    bottom: 1px !important;
    height: 32px;
    line-height: 32px;
    background: rgba(0, 0, 0, 0.2);
}
</style>