import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

import axios from 'axios'
Vue.prototype.$axios=axios

// import axios from 'axios'
// import VueAxios from 'vue-axios'
// Vue.use(VueAxios, axios)

// import videoPlayer from "vue-video-player";
// Vue.use(videoPlayer);


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import * as echarts from 'echarts';
Vue.prototype.$echarst = echarts

//富文本插件
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';


import './font/font.css'

//轮播图
import 'swiper/css/swiper.css'

// console.log(process.env);

import "./components/common/rem.js"

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


