<template>
    <div class="bottom">
        <div class="shangmiande">
            <div class="jie">
                <div class="logo">
                    <img class="logoImg" src="../../assets/smalllogo.png" alt=""/>
                </div>
                <div class="youwenb">
                    <span class="call">联系我们</span>
                    <span class="us">CONTACT US</span>
                    <div class="dizhi">
                        <img src="../../assets/diz.png" alt="" />
                        <span>地址：山西省太原市小店区长风街和信商座14层</span>
                    </div>
                    <div class="iphone">
                        <img src="../../assets/iphone.png" alt="" />
                        <span>电话：0351-2348768</span>
                    </div>
                </div>
            </div>
            <div class="erweima">
                <img src="../../assets/erweima.png" alt="" />
                <div class="sao">
                    <div style="margin-bottom: 10px">扫一扫</div>
                    <div>关注公众号</div>
                </div>
            </div>
        </div>
        <div class="shenm">
            <div class="message">Copyright 2005 Powered by 山西和信基业科技股份有限公司(www.sxhxjy.com) All Rights </div>
            <div style="margin-top: 10px;text-align: center">
                <span class="message">山西和信基业科技股份有限公司 版权所有&nbsp;</span>
                <a class="lianjie" href="https://beian.miit.gov.cn/#/Integrated/index">晋ICP备19001233号&nbsp;<img src="@/assets/gongan.png">&nbsp;联网备案号14019202000556</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
.logo{
    float: left; line-height: 311px; margin-right: 53px;
    @media screen and (max-width:1280px){
        float: left; line-height: 311px; margin-right: 53px; margin-top: -15px;
    }
}
.logoImg{
    width: 145px;
    height: 46px;
    @media screen and (max-width:1280px){
        width: 150px;
        height: 55px;
    }
}
.bottom {
    height: 36.1rem;
    padding: 0px 22rem;
    background-color: rgba(0, 104, 183, 1);
    @media screen and (max-width: 1280px) {
        height: 41rem;
        padding: 0px 18rem;
        background-color: rgba(0, 104, 183, 1);
    }
}
.shangmiande{
    height: 31rem;
}
.jie {
    float: left;
    padding-top: 7.1rem;
}
.youwenb {
    float: right;
    height: 19.4rem;
    padding-left: 2.5rem;
    padding-top: 3.1rem;
    border-left: 1px solid transparent;
    border-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0) 100%
    )
    1 1 1 1;
    @media screen and (max-width: 1280px) {
        float: right;
        height: 20.4rem;
        padding-left: 4.5rem;
        padding-top: 3.1rem;
        border-left: 1px solid transparent;
        border-image: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0) 100%
        )
        1 1 1 1;
    }
}
.call {
    font-size: 20px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    line-height: 23px;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        font-size: 17px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        line-height: 23px;
        color: rgba(255, 255, 255, 1);
    }
}
.us {
    margin-left: 1.25rem;
    font-size: 14px;
    font-weight: 300;
    word-spacing: 5px;
    font-family: "fangzhenglantingxi";
    line-height: 14px;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        margin-left: 1.25rem;
        font-size: 13px;
        font-weight: 300;
        word-spacing: 5px;
        font-family: "fangzhenglantingxi";
        line-height: 14px;
        color: rgba(255, 255, 255, 1);
    }
}
.dizhi img {
    width: 1.5rem;
    height: 1.8rem;
    margin-top: 2.8rem;
    margin-right: 1.8rem;
    @media screen and (max-width: 1280px) {
        width: 1.8rem;
        height: 2.1rem;
        margin-top: 2.8rem;
        margin-right: 1.8rem;
    }
}
.dizhi span {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.25rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.25rem;
        color: rgba(255, 255, 255, 1);
        margin-top: 2rem;
    }
}
.iphone img {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 2.8rem;
    margin-right: 1.56rem;
    @media screen and (max-width: 1280px) {
        width: 2rem;
        height: 2rem;
        margin-top: 2.8rem;
        margin-right: 1.56rem;
    }
}
.iphone span {
    font-size: 17px;
    font-weight: 400;
    line-height: 19px;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 1px;
        color: rgba(255, 255, 255, 1);
    }
}
.erweima {
    float: right;
    margin-top: 12.8rem;
    margin-right: 0.6rem;
    width: 16.4rem;
    height: 7.1rem;
    background-color: rgba(0, 94, 166, 1);
    @media screen and (max-width: 1280px) {
        float: right;
        margin-top: 12.8rem;
        /* margin-right: 0.4rem; */
        width: 19.4rem;
        height: 9.1rem;
        background-color: rgba(0, 94, 166, 1);
    }
}
.erweima img {
    float: left;
    width: 7rem;
    height: 7.06rem;
    margin-right: 1.5rem;
    @media screen and (max-width: 1280px) {
        float: left;
        width: 9rem;
        height: 9.06rem;
        margin-right: 1rem;
    }
}
.sao {
    width: 16.4rem;
    height: 7.1rem;
    font-size: 14px;
    font-weight: 400;
    font-family: "fangzhenglantingzhun";
    margin-top: 2.3rem;
    color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1280px) {
        width: 17.4rem;
        height: 6.1rem;
        font-size: 13px;
        font-weight: 400;
        font-family: "fangzhenglantingzhun";
        margin-top: 2.3rem;
        color: rgba(255, 255, 255, 1);
    }
}
.message{
    font-size: 15px;
    color: #cccccc;
    text-align: center;
    @media screen and (max-width: 1280px) {
        font-size: 13px;
        margin-left: 15rem;
    }
}
.lianjie{
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    @media screen and (max-width: 1280px) {
        font-size: 12px;
    }
}
.shenm{
    @media screen and (max-width: 1280px) {
        float: left;
    }
}
</style>